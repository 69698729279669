﻿.mp-checkout {
	padding-top: 30px;
	padding-right: 30px;
	padding-left: 30px;

	.checkout-error {
		margin: 10px 0;
		padding: 0;
	}
}

.mp-checkout__section {
	margin: 60px 0;
	font-size: 16px;

	&:first-of-type {
		margin-top: 0;
	}
}

.mp-checkout__header {
	margin-bottom: 0.25em;
	font-family: 'futura-pt', sans-serif;
	font-size: 28px;
	font-weight: 700;
}

.mp-checkout__subheader {
	margin-bottom: 0.25em;
	font-family: 'futura-pt', sans-serif;
	font-size: 1.15em;
	text-transform: uppercase;
}

.mp-checkout__grid {
	display: flex;
	flex-direction: row;
	column-gap: 20px;
}

.mp-checkout__box {
	margin: 15px 0;
	padding: 10px;
	line-height: 1.4;
	background-color: #fafafa;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);

	&.is--marketplace {
		border: 2px solid #31cd32;
	}
}

.is--marketplace h3 {
	display: flex;
	align-items: center;

	img {
		max-width: 24px;
	}
}

.form-grid {
	margin: 30px 0 60px;
	max-width: 500px;

	.input {
		width: 100%;
	}

	.label-block {
		margin-bottom: 5px;
	}
}

[class^="form-grid__"] {
	margin-bottom: 5px;
}

@media only screen and (min-width: 480px) {
	.form-grid__row {
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		column-gap: 15px;
	}
}

.form-grid__12 { flex: 12; }
.form-grid__11 { flex: 11; }
.form-grid__10 { flex: 10; }
.form-grid__9 { flex: 9; }
.form-grid__8 { flex: 8; }
.form-grid__7 { flex: 7; }
.form-grid__6 { flex: 6; }
.form-grid__5 { flex: 5; }
.form-grid__4 { flex: 4; }
.form-grid__3 { flex: 3; }
.form-grid__2 { flex: 2; }

.mp-checkout__main {
	flex: 9;
}

.mp-checkout__summary {
	max-width: 500px;
	padding: 15px;
	font-size: 16px;
	background-color: #fafafa;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
}

.mp-summary__header {
	margin-bottom: 30px;
	line-height: 1;

	p {
		margin: 5px 0;
	}
}

.mp-summary {
	margin: 0;
	padding: 0;
	list-style: none;
}

.mp-summary__row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 4px 0;
}

.mp-summary__value {
	font-weight: bold;
}

.mp-summary__action {
	margin-top: 15px;

	.button {
		display: block;
		width: 100%;
		border-radius: 0;
	}
}

.mp-summary .mp-checkout__box.is--marketplace {
	padding: 5px;
}

.a-thing {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
}

.the-right-side {
	position: sticky;
	top: 0;
}

@media only screen and (min-width: 800px) {
	.mp-checkout {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		column-gap: 30px;
	}

	.mp-checkout__summary {
		flex: 3;
		align-self: flex-start;
		position: sticky;
		top: 140px;
		margin-top: 10px;
	}
}

@media only screen and (min-width: 1000px) {
	.mp-checkout {
		column-gap: 45px;
		padding-left: 0;
		padding-right: 0;
	}
}

@media only screen and (max-width: 480px) {
	.checkout-sku__label {
		display: inline-block;
	}
}


.mp-checkout__education {
	display: flex;
	flex-direction: column;
	row-gap: 5px;
	margin: 5px 0;
}

@media only screen and (min-width: 680px) {
	.mp-checkout__education {
		flex-direction: row;
		align-items: center;
		column-gap: 10px;
	}
}