﻿.education-content {
    max-width: 1000px;
    margin: 0 auto;
}

.education-filters {
    padding-top: 5px;
}

.edu-list-item__title a {
    color: #000;
}

.edu-list-item__image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    height: auto;
}

.edu-list-item__image-overlay {
    width: 100%;
}

.edu-list-item__dates {
    font-size: .9em;
}

@media only screen and (max-width: 680px) {
    .edu-list-item__title {
        margin-top: 10px;
    }
}