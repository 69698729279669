.bxgy {
	padding: 0 10px;
}

.bxgy__price {
	text-align: center;
}

.bxgy__qty {
	margin: 15px 0;
	text-align: center;
}

.bxgy__add {
	margin: 15px 0;
	text-align: center;
}

.bxgy__error.lbl--error {
	display: block;
	margin: 15px 0 0;
}

.bxgy__section {
	margin: 15px 0;
	border: 1px solid #ddd;
	border-radius: 2px;
}

.bxgy__inner {
	padding: 10px;
}

.bxgy__header {
	margin: 0 0 15px;
	padding: 0;
	font-size: 16px;
}

.bxgy__quantities {
	padding: 5px 10px;
	border-top: 1px solid #ddd;
}

.bxgy-quantity {
	padding: 2px 0;
}

.bxgy-quantity--required {
	font-weight: bold;
}

.bxgy-product {
	display: grid;
	grid-template-columns: auto 1fr 1fr 1fr;
	align-items: center;
	margin: 25px 0;
	font-size: 11px;

	.bxgy-product__title {
		margin-bottom: 5px;
		font-size: 12px;
		line-height: 1.4;
	}
}

.bxgy-product__photo {
	grid-column: 1;
}

.bxgy-product__header {
	grid-column: 2 / span 3;
}

.bxgy-product__pricing {
	display: grid;
	grid-column: 1 / span 4;
	grid-template-columns: repeat(2, 1fr);
	align-items: center;
	margin: 5px 0 0 0;
}

.bxgy-product__get-pricing {
	grid-column: 1 / span 4;
	margin-top: 15px;
	text-align: center;
}

.bxgy-product__price, .bxgy-product__qty {
	text-align: center;
}

.bxgy-product__price-label {
	display: block;
	margin-bottom: 2px;
	font-size: 10px;
}

.bxgy-product__price-value {
	font-size: 18px;
	font-weight: bold;
}

@media only screen and (min-width: 480px) {
	.bxgy__add-to-cart {
		display: grid;
		grid-template-columns: repeat(3, auto);
		align-items: end;
		justify-content: end;
	}

	.bxgy__price {
		text-align: left;
	}

	.bxgy__add, .bxgy__qty {
		margin: 0 0 0 15px;
	}
}

@media only screen and (min-width: 660px) {
	.bxgy__sections {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 15px;
		align-items: start;
	}
}

@media only screen and (min-width: 920px) {
	.bxgy__header {
		font-size: 18px;
	}

	.bxgy-product {
		grid-template-columns: auto 1fr auto auto;
	}

	.bxgy-product__header {
		grid-column: 2;
	}

	.bxgy-product__pricing {
		grid-column: 3 / span 2;
		grid-gap: 10px;
		margin-left: 15px;
	}

	.bxgy-product__get-pricing {
		grid-column: 4;
		margin-top: 0;
	}

	.bxgy-quantity {
		padding: 2px 10px;
		text-align: right;
	}
}
