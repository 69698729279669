.class-dates {
	max-width: 100%;
	margin: 10px 0;
	border-collapse: collapse;
}

.class-dates th, .class-dates td {
	padding: 5px 10px;
	line-height: 1.4;
}

.class-dates__date {
	width: 100px;
	text-align: center;
}

.class-dates__location {
	width: 180px;
}

.class-dates__seats {
	width: 90px;
	text-align: center;
}

.class-dates__price {
	width: 90px;
	text-align: right;
}

.class-dates__flyer {
	width: 90px;	
}

.class-dates__register {
	width: 125px;
	text-align: center;
}

.class-dates__add {
	white-space: nowrap;
}

.class-dates__time {
	font-size: 0.8em;
}

.class-dates__time--from {
	display: block;
}

.class__directions {
	display: block;
	position: relative;
	margin: 5px 0;
	padding: 0 0 0 24px;
	font-size: 11px;
	line-height: 20px;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 18px;
		height: 18px;
		background: url('/images/map/car.png') no-repeat 0 0;
		background-size: 18px 18px;
		opacity: 0.65;
	}
}

@media only screen and (max-width: 420px) {
	.class-dates {
		width: 100%;
	}

	.class-dates tr {
		display: flex;
		flex-direction: column;
	}

	.class-dates th {
		display: none;
	}

	.class-dates td {
		display: block;
		width: 100%;
		padding: 10px;
		text-align: left !important;
		vertical-align: top;
	}

	.class-dates td::before {
		content: attr(data-hdr);
		display: block;
		font-weight: bold;
	}

	.class-dates .class-dates__name {
		order: -1;
		padding-top: 10px;
		font-size: 1.2em;
		font-weight: bold;
		line-height: 1.2;
	}

	.class-dates .class-dates__name::before {
		font-size: 13px;
	}

	.class-dates .class-dates__date  {
		padding-bottom: 0;
		text-align: left;
	}

	.class-dates__time--from {
		display: inline;
	}
}
