// ---=== Contents ===--- //
/*
 * 1.) Settings
 * #colors
 *
 * 2.) Utilities
 * #grid
 * #custom-select
 *
 * 3.) Generic
 * #box-sizing [TODO: Replace with Normalize.css]
 * #html-5 [TODO: Replace with Normalize.css]
 *
 * 4.) Base
 * #html-body
 * #headings [TODO: Replace with classes]
 * #default-text
 * #default-images
 * #default-form [TODO: Replace with classes]
 * #form-utilities [TODO: Refactor/look at usage]
 * #text-utilities
 * #layout-utilities
 * #responsive-video
 * #list-utilities
 * #element-loading [TODO: Refactor, move to objects]
 *
 * 5.) Objects
 * #wrappers
 * #buttons [TODO: Refactor, split into multiple options]
 * #icons [TODO: Make more consistent]
 *
 * 6.) Components
 * #header-top
 * #header-banner
 * #header-search
 * #site-logo
 * #site-menu
 * #site-footer
 * #social-links
 * #breadcrumbs
 * #product-filters
 * #product-bar [TODO: Refactor]
 * #pagination
 * #page-size
 * #page-sort
 * #product-list-page [TODO: Refactor]
 * #product-list-grid [TODO: Refactor]
 * #product-list-list [TODO: Refactor]
 * #prod-options-related [TODO: Refactor (this should be a product list)]
 * #grid-list-view [TODO: Refactor]
 * #overlay [TODO: Refactor. Combine with spinners, etc]
 * #modal
 * #results-spinner
 * #product-info
 * #product-pricing
 * #product-details
 * #product-sharing
 * #product-images
 * #external-links
 * #tooltip
 * #quick-view-modal
 * #no-stock
 * #added-message
 * #site-locator [TODO: Should probably be its own file]
 * #education [TODO: Separate file?]
 * #education-all [TODO: Separate file?]
 * #education-table
 * #education-list
 * #login-register
 * #register-guest
 * #contact-us
 * #tabs
 * #about-us
 * #home-page-catalogs
 * #quick-order
 * #checkout [TODO: Separate file?]
 * #order-history [TODO: Separate file for account stuff?]
 * #order-details [TODO: Separate file for account stuff?]
 * #account-nav [TOOD: Separate file for account stuff?]
 * #account-information
 * #shopping-cart [TODO: Refactor]
 * #shopping-list
 * #home-page
 * #home-articles
 * #home-facebook
 * #fade-rotator
 * #header-message
 * #article-posts [TODO: Separate file?]
 * #tag-cloud
 * #coupon-code
 * #gallery
 * #saved-cards
 * #popover
 * #sample-program [TODO: Cart file?]
 * #new-cart [TODO: Work into actual cart]
 * #home-categories
 * #pencil-banner
 * #explore-deals
 * #dev-message
 * #multi-select
 * #login-modal
 * #toggler
 * #or
 * #autocomplete
 * #instagram-widget
 *
 * 7.) Overrides
 * #sharing-overrides
 * #layout-overrides
 * #text-overrides
 * #clearfix
 * #temporary-overrides [TODO: Un-temporarize these]
 *
 */



// ---=== Settings ===--- //

// --- #colors --- //
$red: 			rgb(211,  33,  33); // #d32121
$red-light: 	rgb(215, 141, 141); // #d78d8d
$green: 		rgb( 51, 131,  38); // #338326
$green-light: 	rgb(134, 215, 121); // #86d779
$green-lime:	rgb( 50, 205,  50); // #32cd32
$blue:			rgb(  0,  92, 160); // #005ca0

$black:			rgb(  0,   0,   0); // #000000
$white:			rgb(255, 255, 255); // #ffffff

$gray1:			rgb( 51,  51,  51); // #333333
$gray2:			rgb( 76,  76,  76); // #4c4c4c
$gray3:			rgb(126, 126, 126); // #7e7e7e
$gray4:			rgb(153, 153, 153); // #999999
$gray5:			rgb(170, 170, 170); // #aaaaaa
$gray6:			rgb(192, 192, 192); // #c0c0c0
$gray7:			rgb(214, 214, 214); // #d6d6d6
$gray8:			rgb(238, 238, 238); // #eeeeee
$gray9:			rgb(244, 244, 244); // #f4f4f4

$twitter:		rgb( 61, 199, 244); // #3dc7f4
$facebook:		rgb( 59,  89, 152); // #3b5998
$google:		rgb(221,  75,  57); // #dd4b39
$linkedin:		rgb(  0, 101, 155); // #00659b
$pinterest:		rgb(220,  62,  71); // #dc3e47
$youtube:		rgb(228,   2,   2); // #e40202
$wordpress:		rgb( 36, 124, 164); // #247ca4
$rss:			rgb(224, 142,   0); // #e08e00
$instagram:		rgb(119,  62, 177); // #773eb1
$bangstyle:		rgb(148, 151, 202); // #9497ca
$tiktok:		rgb(0, 242, 234); //#00f2ea



// ---=== Utilities ===--- //

@import 'lib/utilities/animations';

// --- #grid --- //

@import 'lib/utilities/grid';

// --- #custom-select --- //

@import 'lib/utilities/custom-select';

.visually-hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	overflow: hidden;
}



// ---=== Generic ===--- //

// --- #box-sizing --- //
*, *::after, *::before {
	box-sizing: inherit;
}

body {
	box-sizing: border-box;
}


// --- #html-5 --- //
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
	display: block;
}



// ---=== Base ===--- //

// --- #html-body --- //

html, body {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	color: $gray2;
	font-family: 'Helvetica', 'Arial', sans-serif;
	font-size: 0.9em;
	background-color: $white;
}


// --- #headings --- //

h1 {
	margin: 0;
	padding: 0;
	font-size: 2.027em;
	font-weight: normal;
	line-height: 1;
	text-transform: uppercase;
}

h2 {
	margin: 0 0 0.85em;
	padding: 0;
	font-size: 1.802em;
	line-height: 1;
}

h3 {
	margin: 0 0 1em;
	padding: 0;
	font-size: 1.602em;
	line-height: 1.05;
}

h4 {
	margin: 0 0 1.25em;
	padding: 0;
	font-size: 1.424em;
	line-height: 1.15;
}

h5 {
	margin: 0 0 1.35em;
	padding: 0;
	font-size: 1.266em;
	line-height: 1.25;
}

h6 {
	margin: 0 0 1.4em;
	padding: 0;
	font-size: 1.125em;
	line-height: 1.4;
}


// --- #default-text --- //

a {
	color: $blue;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

small {
	font-size: 1em;
}


// --- #default-images --- //

img {
	border: none;
}


// --- #default-form --- //

textarea {
	height: 6em;
	font-family: inherit;
}


// --- #form-utilities --- //

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
	-webkit-appearance: none;
}

input[type="number"] {
	-moz-appearance: textfield;
}

.input, .input--xs, .input--sm, .input--md, .input--lg, .input--full, .input--qty, .input--price {
	max-width: 100%;
	padding: 3px 6px;
	border: 1px solid $gray6;
	border-radius: 1px;
}

.input--qty {
	max-width: 3em;
	text-align: center;
}

.input--price {
	max-width: 5em;
	text-align: right;
}

.input--xs { width: 50px; }
.input--sm { width: 175px; }
.input--md { width: 350px; }
.input--lg { width: 500px; }
.input--full { width: 100%; }

.label-block {
	display: block;
	margin-bottom: 2px;
}

.placeholder {
	color: $gray5;
}

.readonly {
	margin-bottom: 0;
	padding: 0;
	font-family: inherit;
	font-size: inherit;
	border: none;
}


// --- #text-utilities --- //

.inactive {
	cursor: default;
	pointer-events: none;
}

.callTopic {
	cursor: pointer;
}

.phone-nr {
	white-space: nowrap;
}

.error {
	color: $red;
}

.lbl--error {
	color: $red;
	font-weight: bold;
}

.lbl--success {
	color: $green-lime;
	font-weight: bold;
}

.txt--hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
	overflow: hidden;
	clip: rect(0 0 0 0);
}


// --- #layout-utilities --- //

.basic-line {
	height: 1px;
	background: $gray6;
	border: none;
}

.evenrow {
	background-color: $gray8;
}

.oddrow {
	background-color: $white;
}

.img--right {
	float: right;
	max-width: 50%;
	margin: 0 0 15px 15px;
}

.img--left {
	float: left;
	max-width: 50%;
	margin: 0 15px 15px 0;
}

.basic-form label {
	display: block;
	margin: 0 0 2px;
}

.basic-form > li {
	margin: 0 0 5px;
}

.basic-form .basic-form--city {
	float: left;
	width: 50%;
	padding-right: 5px;
}

.basic-form .basic-form--zip {
	float: left;
	width: 50%;
	padding-left: 5px;
}

.resp-img {
	display: block;
	height: auto;
	max-width: 100%;
}


// --- #responsive-video --- //

.responsive-video {
	position: relative;
	height: 0;
	max-width: 100%;
	padding-top: 56.25%;
	overflow: hidden;
}

.responsive-video iframe, .responsive-video object, .responsive-video embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.responsive-video__link {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 5000;
	background-color: rgba(255, 255, 255, 0);
	opacity: 0;
}


// --- #list-utilities --- //

.list--plain {
	margin: 0;
	padding: 0;
	list-style: none;
}

.list--inline {
	margin: 0;
	padding: 0;
	list-style: none;
}

.list--inline > li {
	display: inline-block;
	vertical-align: middle;
}

.faux-table {
	display: table;
	width: 100%;
	margin: 0;
	padding: 0;
	line-height: 1.2;
}

.faux-table > li, .faux-table__row {
	display: table-row;
}

.faux-col, .faux-table__col {
	display: table-cell;
	padding: 5px 10px;
}

.faux-col:first-of-type, .faux-table__col:first-of-type {
	padding-right: 0;
	text-align: right;
	white-space: nowrap;
}

.faux-col .readonly, .faux-table__col .readonly {
	width: 100%;
}

.list--left {
	margin: 0;
	padding: 0 0 0 1.5em;
	text-align: left;
}


// --- #element-loading --- //

.loading {
	position: relative;
	overflow: hidden;
}

.loading::before {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1000;
	background: url("/images/white-60.png") repeat;
	background: rgba(255, 255, 255, 0.6);
}

.loading::after {
	content: "Loading";
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1100;
	margin: auto;
	width: 16px;
	height: 16px;
	text-indent: 100%;
	overflow: hidden;
	white-space: nowrap;
	background: url('/images/spinner-small.gif') no-repeat 0 0;
}


// ---=== Objects ===--- //

// --- #wrappers --- //

.title-wrap {
	padding-top: 2em;
}

@media only screen and (max-width: 680px) {
	.title-wrap {
		padding-top: 0;
	}
}

.main-content {
	z-index: 1;
	max-width: 1000px;
	margin: 0 auto;
	min-height: 400px;
	background-color: $white;
}

.header-wrap {
	padding: 10px 10px 5px;
}


// --- #buttons --- //

@import 'lib/components/buttons';


// --- #icons --- //

.burger {
	display: inline-block;
	position: relative;
	width: 15px;
	height: 15px;
	margin: 0;
	vertical-align: text-top;
}

.burger::before, .burger::after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	height: 3px;
	background: $white;
}

.burger::before {
	top: auto;
	bottom: 0;
}

.burger__middle {
	display: block;
	position: absolute;
	height: 3px;
	top: 6px;
	right: 0;
	left: 0;
	background: $white;
}

.offscreen-active .burger {
	vertical-align: baseline;
}

.offscreen-active .burger__middle {
	opacity: 0;
}

.offscreen-active .burger::before, .offscreen-active .burger::after {
	top: 50%;
	bottom: auto;
}

.offscreen-active .burger::before {
	transform: rotate(45deg);
}

.offscreen-active .burger::after {
	transform: rotate(-45deg);
}

.i--twitter, .i--facebook, .i--linkedin, .i--pinterest, .i--youtube, .i--wordpress, .i--feed, .i--instagram, .i--bangstyle, .i--email, .i--tiktok {
	display: block;
	width: 25px;
	height: 25px;
	background: #444 url("/images/socialicons.png") no-repeat 0 0;
	border-radius: 100%;
	text-indent: 100%;
	overflow: hidden;
	white-space: nowrap;
}

.i--email {
	background-image: url("/images/email-icon.png");
}

.i--twitter { background-position: 2px 0; }
.i--facebook { background-position: -24px 0; }
.i--linkedin { background-position: -542px 0; }
.i--pinterest { background-position: -256px 0; }
.i--youtube { background-position: -152px 0; }
.i--wordpress { background-position: -595px 0; }
.i--feed { background-position: -646px 0; }
.i--instagram { background-position: -696px 1px; }
.i--bangstyle { background-position: -724px 1px; }
.i--tiktok {	background-position: -748px 0px; }
// .i--email			{ }

.i--twitter-hover { background-color: $twitter; }
.i--facebook-hover { background-color: $facebook; }
.i--linkedin-hover { background-color: $linkedin; }
.i--pinterest-hover { background-color: $pinterest; }
.i--youtube-hover { background-color: $youtube; }
.i--wordpress-hover { background-color: $wordpress; }
.i--feed-hover { background-color: $rss; }
.i--instagram-hover { background-color: $instagram; }
.i--bangstyle-hover { background-color: $bangstyle; }
.i--tiktok-hover { background-color : $tiktok; }
.i--email { background-color: $gray1; }

.i--spinner {
	width: 64px;
	height: 64px;
	margin: 0 auto; // TODO: Remove positioning
	background: url("/images/spinner.gif");
	text-indent: 100%;
	overflow: hidden;
	white-space: nowrap;
}

.icon--fb::before {
	content: "";
	display: inline-block;
	width: 20px;
	height: 20px;
	margin: 0 0.25em;
	overflow: hidden;
	white-space: nowrap;
	text-indent: 100%;
	background: $facebook url("/images/socialicons.png") no-repeat -25px -2px;
	vertical-align: bottom;
}

.home-social__item {
	.i--twitter, .i--facebook, .i--linkedin, .i--pinterest, .i--youtube, .i--instagram, .i--email, .i--tiktok {
		width: 54px;
		height: 54px;
		background: url("/images/socialicons-home-black.png") no-repeat 0 0;
	}

	.i--instagram {
		background-position: -145px 0;
	}

	.i--facebook {
		background-position: -72px 0;
	}
}

/**
 * 5 - Components
 */

// --- #header-top --- //

.header-top {
	position: fixed;
	z-index: 9000;
	width: 100%;
	background-color: #ddd;
	transition: background-color 1s, color 1s;
}

.header-top--added {
	background-color: $green;
	color: $white;
	transition: background-color 0.3s, color 0.3s;
}

.header-top__Impersonate, .header-top__TestMode {
	display: inline-block;
	margin-bottom: 4px;
	padding: 1px 4px;
	color: $white;
	font-size: 11px;
	letter-spacing: 1px;
	text-transform: none;
	background-color: #ff0000;
}

.header-top__account {
	padding: 5px;
}

.header-top__account, .header-top__inner {
	max-width: 1000px;
	margin: 0 auto;
}

.header-top__links {
	float: right;
}

.header-top__link {
	float: left;
	margin: 0 0 0 5px;
	padding: 0 0 0 5px;
	border-left: 1px solid $gray3;
}

.header-top__link:first-child {
	margin: 0;
	padding: 0;
	border-left: none;
}

.header-top__assistance {
	margin: 0;
	padding: 0;
	text-transform: uppercase;
}

.header-top__link > a {
	color: $gray1;
}

.header-top a {
	color: inherit;
}

.header-top__countdown {
	clear: both;
	padding: 5px 0;
	font-size: 12px;
	font-style: italic;
	text-align: right;
}

@media only screen and (max-width: 680px) {
	.header-top {
		position: static;
	}

	.header-top__links {
		float: none;
		padding: 0 0 5px;
	}

	.header-top__link:nth-child(2) {
		margin: 0;
		padding: 0;
		border-left: none;
	}

	.header-top__link--greeting {
		display: none;
	}

	.header-top__link--login {
		text-align: left;
	}

	.header-top__countdown {
		text-align: left;
	}
}


// --- #account-header --- //

@import 'lib/components/account-header';
@import 'lib/components/account/header-nav';
@import 'lib/components/account/section-nav';


// --- #header-banner --- //

@import 'lib/components/header-banner';


// --- #header-search --- //

@import 'lib/components/header-search';


// --- #site-logo --- //

@import 'lib/components/site-logo';


// --- #site-menu --- //

@import 'lib/components/site-nav';


// --- #site-footer --- //

.site-footer {
	max-width: 1000px;
	margin: 0 auto;
	padding: 15px 0;
	background-color: $white;
}

.site-footer__panel {
	padding: 0 0 15px;
}

.site-footer__header {
	margin: 0 0 5px;
	padding: 15px 0 5px;
	font-size: 1em;
	font-weight: bold;
	text-transform: uppercase;
	border-bottom: 1px solid #c8c8c8;
}

.site-footer__item {
	clear: both;
	padding: 5px 0;
}

.site-footer__copyright {
	margin-top: 10px;
	padding: 10px 0;
	color: $gray3;
	font-size: 0.8em;
	text-align: center;

	.site-footer__site-by {
		text-transform: none;
	}
}

// --- #social-links --- //

.social__item {
	float: left;
	width: 25px;
	height: 25px;
	margin: 0 7px 7px 0;
	overflow: hidden;
}

.social__link {
	display: block;
	transition: margin-top 0.2s;
}

.social__item:hover .social__link:first-child {
	margin-top: -25px;
}


// --- #breadcrumbs --- //

@import 'lib/components/breadcrumbs';


// --- #product-filters --- //

.filters {
	margin: -10px 0 10px;
}

.filters__header {
	margin: 10px 0 0;
	padding: 10px;
	color: $white;
	font-size: 1em;
	font-weight: bold;
	background-color: $gray1;
	border-radius: 3px;
}

.filters__header a {
	color: $white;
}

.filters__toggle {
	float: left;
	display: block;
	font-size: 1em;
	cursor: default;
}

.filters__toggle:hover {
	text-decoration: none;
}

.filters__clear {
	float: right;
}

.filters__section--type {
	padding-bottom: 10px;
}

.filters__link {
	display: block;
	padding: 5px 10px;
	color: $gray1;
}

.filters__link:hover {
	background-color: $gray8;
}

.filters__link--selected, .filters__link--selected:hover {
	font-weight: bold;
	background-color: $gray7;
	text-decoration: none;
	cursor: default;
}

@media only screen and (max-width: 680px) {
	.filters {
		margin-top: 0;
		border-radius: 3px;
	}

	.filters__section {
		clear: both;
	}

	.filters__section--type {
		padding-bottom: 0;
	}

	.filters__header {
		margin-top: 0;
		border-radius: 0;
	}

	.filters__clear {
		clear: none;
		float: right;
	}

	.filters__toggle {
		cursor: pointer;
	}

	.filters__toggle::before {
		content: '+ ';
		display: inline-block;
		width: 1em;
		text-align: center;
	}

	.filters__list > li {
		float: left;
		width: 50%;
	}

	.filters__list > li:nth-child(odd) {
		clear: left;
	}

	.sidebar--enabled .filters__list {
		display: none;
	}

	.filters__section--expanded .filters__toggle::before {
		content: '- ';
	}

	.filters__section--expanded .filters__list {
		display: block;
	}
}

@media only screen and (max-width: 420px) {
	.filters__list > li {
		float: none;
		width: auto;
	}
}


// --- #product-bar --- //

.product-bar {
	clear: both;
	padding: 5px;
	background-color: #ddd;
}

.product-bar__left {
	float: left;
	width: 30%;
}

.product-bar__center {
	float: left;
	width: 40%;
}

.product-bar__right {
	float: right;
	width: 30%;
}

.product-bar__left--auto {
	float: left;
	width: auto;
	padding: 5px 0; // TODO: Should probably find a better way to do this...
}

.product-bar__right--auto {
	float: right;
	width: auto;
}


// --- #pagination --- //

.pagination {
	text-align: center;
	white-space: nowrap;
}

.pagination__controls {
	display: table;
	margin: 0 auto;
}

.pagination__list {
	float: left;
	border: 1px solid #ccc;
	border-radius: 3px;
}

.pagination__item {
	float: left;
	border-left: 1px solid #ccc;
}

.pagination__item:first-child {
	border-left: none;
}

.pagination__link {
	display: block;
	margin: 0;
	padding: 5px;
	width: 2em;
	color: $gray1;
	background-color: $white;
	text-align: center;
	text-decoration: none;
	transition: background 0.4s;
}

.pagination__link:hover {
	text-decoration: none;
	background-color: #f0f0f0;
}

.pagination__link--inactive, .pagination__link--inactive:hover {
	opacity: 0.5;
	cursor: default;
}

.pagination__link--active, .pagination__link--active:hover {
	color: $white;
	background: $gray1;
}

.pagination__prev, .pagination__next {
	float: left;
	width: auto;
	padding: 5px 10px;
	border: 1px solid #ccc;
	border-radius: 3px;
}

.pagination__prev {
	margin: 0 5px 0 0;
}

.pagination__prev::before {
	content: '\2190\0020';
}

.pagination__next {
	margin: 0 0 0 5px;
}

.pagination__next::after {
	content: ' \2192';
}

@media only screen and (max-width: 640px) {
	.pagination {
		float: none;
		clear: both;
		width: auto;
		padding-top: 10px;
	}
}


// --- #page-size --- //

.page-size {
	text-align: right;
}

.page-size__count {
	display: inline-block;
	margin: 0 0 0 5px;
	padding: 5px 0;
	white-space: nowrap;
}

.page-size__controls {
	position: relative;
	display: inline;
}

.page-size__select {
	padding: 4px 5px;
	margin-bottom: 0;
	line-height: 1;
}

.quick-order .page-size__count {
	padding: 3px 0 0;
	font-weight: bold;
}

@media only screen and (max-width: 640px) {
	.page-size {
		width: 50%;
	}
}


// --- #page-sort --- //

.page-sort {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}

.page-sort__select {
	float: left;
	width: 75%;
	margin-bottom: 0;
	padding: 4px 8px;
	line-height: 1;
}

@media only screen and (max-width: 640px) {
	.page-sort {
		width: 50%;
	}
}


// --- #product-card --- //

@import 'lib/components/product-card';


// --- #product-list-page --- //

.product-content {
	max-width: 1000px;
	margin: 0 auto;
}

.product-list {
	margin-top: 5px;
}

.brand-img img {
	display: block;
	max-width: 100%;
	height: auto;
	max-height: 224px;
}

.brand-img-banner {
	display: block;
	width: 100%;
	height: auto;
}

.search--no-results {
	margin: 0 0 15px;
	padding: 0;
}


// --- #product-list-grid --- //

@import 'lib/components/product-list';
@import 'lib/components/product-grid';

.product-meta {
	display: none;
	visibility: hidden;
}

.prod-list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	font-size: 0.9em;
}

.prod-list__item {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 25%;
	margin: 0 0 1.5em;
	padding: 10px;
	text-align: center;
	border: 1px solid transparent;
	border-radius: 2px;
	transition: border-color 0.3s;
}

.prod-list__item:hover {
	border-color: $gray6;
}

.prod-list__item:nth-child(4n+1) {
	clear: both;
}

.prod-list__item--clear {
	clear: both\9;
}

.prod-list__link {
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;
	color: $gray1;
	line-height: 1.3;
	text-decoration: none;

	&:hover {
		color: inherit;
		text-decoration: none;
	}
}

.prod-list__image {
	flex: 1 auto;
}

.prod-list__image .prod-list__photo {
	max-height: none;
}

.prod-list__photo {
	display: block;
	height: auto;
	max-width: 100%;
	max-height: 150px;
	margin: 0 auto;
}

.prod-list__header {
	margin: 0;
	padding: 1em 0 0;
	font-size: 14px;
	font-weight: normal;
}

.prod-list__size, .prod-list__sku {
	margin: 0;
	padding: 0;
}

.prod-list__price {
	margin: 15px 0;
	padding: 0;
}

.prod-list__description {
	flex: 1 0 auto;
	min-height: 0; // Temporary fix for Chrome 48 maybe?
}

.prod-list__quickview {
	position: absolute;
	top: 50%;
	left: 50%;
	opacity: 0;
	pointer-events: none;
	transform: translate(-50%, -50%);
	transition: opacity 0.3s;
}

.prod-list__item:hover .prod-list__quickview {
	opacity: 1;
	pointer-events: auto;
}

@media only screen and (max-width: 780px) {
	.prod-list__item {
		width: 33.33333%;
	}

	.prod-list__item:nth-child(4n+1) {
		clear: none;
	}

	.prod-list__item:nth-child(3n+1) {
		clear: both;
	}
}

@media only screen and (max-width: 500px) {
	.prod-list__item {
		width: 50%;
	}

	.prod-list__item:nth-child(3n+1) {
		clear: none;
	}

	.prod-list__item:nth-child(odd) {
		clear: both;
	}
}

@media only screen and (max-width: 420px) {
	.prod-list__item {
		width: 100%;
		margin-bottom: 0;
	}
}

.component-call {
	margin: 8px 4px;
}

.component-call--hidden {
	display: none;
	visibility: hidden;
}


// --- #product-list-vertical --- //

.prod-list--vertical .prod-list__item {
	width: 100%;
	float: none;
}


// --- #product-list-list --- //

.prod-list__list {
	position: relative;
	width: 100%;
	padding: 10px 0;
	//border-bottom: 1px solid $gray6;
}

.list-link {
	color: $gray1;
}

.list-link:hover {
	text-decoration: underline;
}

.list-image {
	float: left;
	padding-right: 8px;
	max-width: 75px;
}

.list-name {
	width: 64%;
	padding: 0 5px;
}

.list-name h3 {
	margin: 0;
	padding: 0;
	font-size: 1.15em;
	font-weight: normal;
}

.list-name p {
	margin: 0;
	padding: 0;
}

.list-name .list-sku {
	padding: 2px 0 10px;
	font-size: 1em;
}

.list-cart, .list-price, .list-stock {
	display: inline-block;
	padding: 0 5px;
	text-align: right;
	vertical-align: middle;
}

.list-cart {
	padding-top: 5px;
}

.list-name, .list-pricing {
	display: inline-block;
	vertical-align: middle;
}

.list-pricing {
	width: 35%;
	text-align: right;
}

.list-actions--top {
	border-bottom: 1px solid $gray6;
}

@media only screen and (max-width: 420px) {
	.list-name, .list-pricing {
		width: 100%;
	}

	.list-pricing {
		margin-top: 15px;
		text-align: left;
	}
}


// --- #prod-options-related --- //

.product-list-inner {
	position: relative;
}

.prod-options-wrap, .related-prod-wrap {
	padding-top: 30px;
}

.prod-options-inner, .related-prod-inner {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	max-height: none;
	overflow-y: visible;
}

.related-prod-inner {
	max-height: 300px;
}

.prod-option, .related-prod {
	position: relative;
	width: 20%;
	margin: 0 0 10px;
	padding: 4px;
	text-align: center;
	border: 1px solid transparent;
}

.related-prod {
	width: 50%;
}

.prod-options-inner .prod-list__item, .prod-options-inner .prod-list__item:hover {
	border: none;
}

.prod-option:hover, .related-prod:hover {
	border-color: $black;
}

.prod-option .onsale, .related-prod .onsale {
	margin: 5px 0;
}

.prod-option:nth-child(5n+1) {
	clear: both;
}

.prod-option--clear {
	clear: both\9;
}

.related-prod:nth-child(odd) {
	clear: both;
}

.related-prod--clear {
	clear: both\9;
}

.prod-option-desc {
	padding: 2px;
}

.prod-option-name {
	margin: 0;
	padding: 0;
	font-size: 0.9em;
	font-weight: bold;
}

.prod-option-size {
	display: block;
	font-size: 0.9em;
	font-weight: normal;
}

.dialog-create-list {
	padding: 15px 0 0;
}

.dialog-create-list .button, .dialog-select-list .button {
	margin-top: 5px;
}

.prod-options-cart {
	margin: 5px 0;
}

.prod-option p {
	margin: 0;
	padding: 10px 0 0;
}

.related-prod-link {
	display: block;
	color: $gray1;
}

.related-prod-link:hover {
	text-decoration: underline;
}

.related-prod .prod-option-name {
	margin: 0 0 5px;
}

.prod-option--added, .prod-option--added:hover {
	border: 1px solid $green !important;
}

@media only screen and (max-width: 860px) {
	.prod-option {
		width: 25%;
	}

	.prod-option:nth-child(5n+1) {
		clear: none;
	}

	.prod-option:nth-child(4n+1) {
		clear: both;
	}
}

@media only screen and (max-width: 740px) {
	.prod-option {
		width: 33.33333333%;
	}

	.prod-option:nth-child(4n+1) {
		clear: none;
	}

	.prod-option:nth-child(3n+1) {
		clear: both;
	}
}

@media only screen and (max-width: 540px) {
	.prod-option {
		width: 50%;
	}

	.prod-option:nth-child(3n+1) {
		clear: none;
	}

	.prod-option:nth-child(odd) {
		clear: both;
	}
}

@media only screen and (max-width: 380px) {
	.related-prod {
		float: none;
		width: 100%;
	}
}

@media only screen and (max-width: 320px) {
	.prod-option, .related-prod {
		float: none;
		width: 100%;
	}
}


// --- #grid-list-view --- //

@import 'lib/components/grid-list-view';


// --- #overlay --- //

.overlay {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 9001;
	// background: rgb(33, 33, 33);
	background: rgba(0, 0, 0, 0.5);
}


// --- #modal --- //

.modal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	max-width: 350px;
	margin: auto;
	padding: 15px;
	color: #ccc;
	text-align: center;
	background: rgb(75, 75, 75);
	background: rgba(0, 0, 0, 0.6);
	border-radius: 3px;
}

.modal a {
	color: $white;
}

.modal--spinner {
	max-height: 200px;
}

.modal--timeout {
	top: 30%;
	right: 2%;
	bottom: auto;
	left: 2%;
}


// --- #results-spinner --- //

.spinner__header {
	margin: 10px 0 0;
	padding: 0;
}

.spinner-panel {
	display: block;
	position: absolute;
	z-index: 900;
	top: 75px;
	bottom: auto;
	width: 94px;
	max-height: 94px;
}

@media only screen and (max-width: 420px) {
	.modal--spinner {
		top: 50%;
		right: 5%;
		bottom: auto;
		left: 5%;
		width: auto;
		max-height: none;
		transform: translateY(-50%);
	}

	.spinner-panel {
		top: 200px;
	}
}

.banner-spinner {
	position: absolute;
	top: 50%;
	right: 0;
	left: 0;
	width: 32px;
	margin: -16px auto 0;
}

.banner-spinner img {
	width: auto;
	max-width: 100%;
}

.spinner--view {
	display: block;
	position: absolute;
}


// --- #product-info --- //

.prod-info__header {
	padding: 0 0 0.75em;
	border-bottom: 1px solid #ddd;
}

.prod-info__name {
	margin: 0;
	padding: 0;
	color: $gray1;
	font-weight: normal;
	line-height: 1.15;
	text-transform: uppercase;
}

.prod-info__brand {
	margin: 0;
	padding: 0;
	color: $gray1;
	font-size: 1.25em;
	font-weight: bold;
	line-height: 1;
}

.prod-info__data {
	margin: 0.75em 0 0;
}

.prod-info__item {
	margin: 0 0 0.25em;
}

.prod-info__price-note {
	margin-top: 0;
	font-size: 0.85em;
	font-weight: bold;
}

.prod-info__no-price {
	margin: 10px 0;
}

.prod-info__short-desc {
	display: block;
	margin-top: 1.5em;
	line-height: 1.6;
}

.prod-info__full {
	font-size: 1.25em;
}

.prod-info--view .prod-info__name {
	font-size: 1.25em;
}

.prod-info--view .prod-info__short-desc {
	margin-top: 0.5em;
}


// --- #product-details --- //
.prod-detail-wrap {
	padding-top: 30px;
}

.prod-detail-wrap h3 {
	margin: 0.25em 0 0;
	padding: 0;
	font-size: 1.17em;
}

@media only screen and (max-width: 420px) {
	.prod-detail-wrap {
		padding-left: 5px;
		padding-right: 5px;
	}
}


// --- #product-pricing --- //
@import 'lib/components/pricing';


// --- #product-accordion --- //
@import 'lib/components/accordion';


// --- #page-sharing --- //
/*@import 'lib/components/page-share';*/


// --- #product-images --- //

.product-img {
	margin: 0 0 15px;
}

.product-img img, .product-img__feature {
	display: block;
	max-width: 100%;
	height: auto;
	margin: 0 auto;
}

.product-img__thumbs {
	display: table;
	margin: 10px auto;
}

.product-thumb {
	float: left;
	width: 75px;
	height: 75px;
	margin: 0 5px;
	border: 1px solid transparent;
}

.product-thumb--selected {
	border: 1px solid $gray1;
}

.product-thumb:hover {
	border: 1px solid $gray5;
}

.product-thumb--selected:hover {
	border: 1px solid $gray1;
}

@import 'lib/components/view-photo';


// --- #buy x get y --- //
@import 'lib/components/buy-x-get-y';

.deal-sku__link {
	color: #00bfff;
	font-weight: bold;
	text-decoration: underline;
}

// --- #external-links --- //
.external {
	padding: 5px 0 0;
	margin: 0 0 5px;
}

.external__link {
	color: $gray1;
	font-weight: bold;
}

.external__img, .external__price {
	display: inline-block;
	margin: 0 5px 0 0;
	padding: 0;
	vertical-align: middle;
}

.external__price:hover {
	text-decoration: underline;
}


// --- #tooltip --- //

.tip_trigger {
	cursor: pointer;
}

.tip {
	position: absolute;
	z-index: 20001;
	min-width: 175px;
	padding: 10px;
	color: $black;
	background: $white;
	border: 1px solid $gray1;
	border-radius: 3px;
}

.tip_trigger .tip {
	display: none;
}

.checkout .tip_trigger {
	color: $blue;
	text-decoration: underline;
}


// --- #quick-view-modal --- //

.quick-modal, .add-cart-dialog {
	display: none;
}

.ui-widget, .quick-modal {
	font-size: 1em;
}

.quick-error {
	font-weight: bold;
}

.ui-dialog .ui-dialog-title {
	display: none;
}

.ui-dialog-titlebar {
	background: none;
	border: none;
}

.ui-dialog {
	padding-top: 20px;
	padding-bottom: 20px;
}

.ui-dialog .ui-dialog-titlebar-close {
	top: 0;
	right: 5px;
}

.ui-dialog .ui-dialog-buttonpane {
	display: none;
}

@media only screen and (max-width: 540px) {
	.quick-modal {
		font-size: 0.85em;
	}
}


// --- #no-stock --- //

.nostock {
	font-size: 1.2em;
	font-weight: bold;
}

.nostocksmall {
	font-size: 1em;
	font-weight: bold;
	line-height: 22px;
}

.nostocklarge {
	font-size: 1.5em;
	font-weight: bold;
}

.list-tbl .nostock {
	max-width: 70px;
	font-size: 14px;
	text-align: center;
}

.prod-option .nostocksmall, .related-prod .nostocksmall {
	display: block;
	margin: 5px 0;
}


// --- #added-message --- //

@import 'lib/components/added';


// --- #site-locator --- //

@import 'lib/components/site-locator';


// --- #education --- //

.education-line {
	height: 1em;
	margin: 10px 0;
	background: $black;
	border: none;
}

.education-nav a {
	display: block;
	margin: 0 0 2px;
	padding: 5px;
	color: $black;
	font-size: 16px;
	font-weight: bold;
	text-align: center;
	text-decoration: none;
	background: $gray6;
}

.education-nav a:hover {
	background: $gray5;
}

.education--active a, .education--active a:hover {
	color: $white;
	background: $black;
}

.education-img {
	padding: 0;
}

.education-img__ftr {
	display: block;
	max-width: 100%;
	height: auto;
	margin: 0 auto;
}

.education-hdr {
	margin: 10px 0;
	padding: 5px 10px;
	color: $white;
	font-size: 1em;
	font-weight: bold;
	background: $black;
}

.class-list__class {
	margin: 0 0 30px;
	padding: 0 10px;
}

.class-list h4 {
	margin: 0 0 0.25em;
	font-size: 1.2em;
	font-weight: bold;
}

.class-list p {
	margin: 0 0 1.5em;
	padding: 0;
}

.class-img {
	float: left;
	max-width: 25%;
	margin-right: 15px;
	height: auto;
}

.class-desc {
	line-height: 1.5;
	overflow: hidden;
}

.register-hdr {
	padding-bottom: 15px;
}

@media only screen and (max-width: 420px) {
	.class-img {
		display: block;
		float: none;
		max-width: 100%;
		margin: 0 auto 10px;
	}
}


// --- #education-all --- //
.education-all {
	padding: 0 0 0 15px;
}

.education-all__header {
	margin: 0;
	padding: 5px 0 0.5em;
	font-size: 1.25em;
}

.education-all__filters {
	margin: 5px;
	padding: 5px 0;
	border-color: #ddd;
	border-style: solid;
	border-width: 1px 0;
}

.education-all__call {
	text-align: right;
}

.education-all__filters .row {
	margin-right: 0;
	margin-left: 0;
}

.education-all .class-dates {
	width: 100%;
}

@media only screen and (max-width: 680px) {
	.education-all {
		margin-top: 15px;
		padding: 0 10px;
	}
}

// --- #education-table --- //

@import 'lib/components/education-table';

// --- #education-calendar --- //

@import 'lib/components/calendar';

// --- #education-list --- ("new" edu layout) //

@import 'lib/components/education-list';

// --- #login-register --- //

.register-have {
	padding-bottom: 10px;
}

.reg-label {
	width: 100px;
	text-align: right;
}

.reg-field {
	width: 140px;
}

.forgot-form {
	padding: 0 0 10px;
}

.forgot-form .button {
	margin-top: 5px;
}

.registererror {
	font-weight: bold;
}

.thankyou {
	font-size: 1.5em;
}

.register {
	padding: 20px 10px;
}

.register__description {
	margin: 10px 0;
}

.register .row {
	margin: 0 -10px;
}

.register h3 {
	margin: 15px 0;
}

.register h3 label {
	font-size: 0.8em;
	font-weight: normal;
}

.register .input--full, .register .input--sm {
	margin-bottom: 5px;
}

@media only screen and (max-width: 420px) {
	.register h3 label {
		display: block;
	}
}

.quick-register .button {
	margin-top: 10px;
}

@import 'lib/components/login';


// --- #register-guest --- //

.register-options {
	margin: 0 -10px;
	overflow: hidden;
}

.register-options__choice {
	float: left;
	width: 50%;
	padding: 0 10px;
	text-align: center;
}

@media only screen and (max-width: 420px) {
	.register-options {
		margin: 0;
	}

	.register-options__choice {
		float: none;
		width: 100%;
	}
}


// --- #contact-us --- //

@import 'lib/components/contact-us';


// --- #tabs --- //

.tabs {
	margin: 30px 0 0;
}

.tabs-nav {
	margin: 0;
	padding: 0;
	list-style: none;
	border-bottom: 1px solid #ddd;
	overflow: hidden;
}

.tabs-nav > li {
	float: left;
	margin-right: 5px;
}

.tabs-nav a {
	display: block;
	padding: 8px 16px;
	color: $gray1;
	line-height: 1;
	background: $gray8;
	border: 1px solid #ddd;
	border-bottom: none;
	border-radius: 3px 3px 0 0;
}

.tabs-nav a:hover {
	text-decoration: none;
}

.tabs-nav .tabs-nav--active {
	color: $white;
	background: $black;
}

.tabs-content {
	min-height: 250px;
	padding: 15px;
}


// --- #about-us --- //

.page-header {
	margin: 0 auto;
	padding: 10px 10px 0;
	overflow: auto;
}

.main-header {
	float: left;
}

.page-help {
	float: right;
}

.page-help p {
	margin: 0;
	padding: 0;
}

.page-wrap {
	padding-top: 15px;
	padding-bottom: 15px;
}

.page-content {
	font-size: 14px;
	line-height: 2;
}

.page-content p {
	margin: 0;
	margin-bottom: 2em;
	padding: 0;
}

.page-content h3 {
	font-size: 1em;
}

.page-content img {
	display: block;
	max-width: 100%;
	height: auto;
}

.category-header {
	margin: 0;
	padding: 2px;
	font-size: 1em;
	font-weight: bold;
}

.category-links {
	margin: 0 0 20px;
}

.category-link {
	padding: 3px 0 0 10px;
}

.category-links a {
	color: $gray2;
}

.category-links a:hover {
	color: $gray1;
}

.category-links .selected {
	color: $gray1;
	font-weight: bold;
}

@media only screen and (max-width: 680px) {
	.page-sections .filters__list > li {
		float: none;
		width: auto;
	}
}

@media only screen and (max-width: 680px) {
	.page-wrap {
		padding-top: 30px;
	}
}
@media only screen and (max-width: 420px) {
	.page-wrap {
		margin-right: 0;
		margin-left: 0;
		padding: 15px 0 0;
	}

	.page-header {
		padding: 10px 5px 0;
	}

	.page-help {
		clear: both;
		float: none;
		margin: 15px 0;
	}

	.page-sections .filters {
		padding-top: 30px;
	}
}


// --- #home-page-catalogs --- //

@import 'lib/components/home-page-catalogs';


// --- #quick-order --- //

.quick-order {
	margin-top: 15px;
}

.quick-section {
	display: inline-block;
}

.quick-list {
	padding-top: 17px;
}

.quick-list .form-el {
	width: 100%;
}

.quick-section__search {
	display: none;
}

@media only screen and (max-width: 680px) {
	.quick-list {
		padding-top: 10px;
	}

	.quick-list .row {
		padding-top: 10px;
	}
}

@media only screen and (max-width: 420px) {
	.quick-list .button {
		display: block;
		margin: 0 0 5px;
		text-align: center;
	}
}


// --- #checkout --- //

@import 'lib/components/credit-cards';
@import 'lib/components/verify-billing';
@import 'lib/components/checkout/coupons-and-credits';

.checkout-header-wrap {
	padding-top: 10px;
}

.checkout select {
	padding: 2px;
}

.checkout h3 {
	margin: 0 0 0.25em;
	font-size: 1em;
}

.checkout .checkout-info__header {
	margin: 14px 0 7px;
	line-height: normal;
}

.checkout-progress-bar {
	margin: 10px 0 0;
	background-color: $black;
	overflow: auto;
}

.checkout-progress {
	float: left;
	padding: 8px 16px;
	color: $gray4;
	color: rgba(255, 255, 255, 0.6);
	text-align: center;
}

.checkout-progress-selected {
	font-weight: bold;
	color: $white;
}

.checkout-error {
	display: block;
	padding: 5px 0;
	font-weight: bold;
}

.checkout-col {
	padding: 10px 0 20px;
}

.checkout-header {
	margin: 0;
	padding: 0 0 2px;
	font-size: 1.1em;
	font-weight: bold;
}

.select-payment {
	display: inline-block;
	vertical-align: middle;
}

.payment-label {
	padding-top: 20px;
}

.payment-option {
	padding: 0 0 15px;
	font-size: 1.15em;
}

.payment-option td {
	padding-right: 25px;
}

.checkout-info {
	padding-bottom: 30px;
}

.checkout-note {
	padding-bottom: 15px;
}

.checkout-tbl {
	width: 100%;
	margin: 10px 0;
	border-collapse: collapse;
	border-spacing: 0;
}

.checkout-tbl th {
	padding: 5px 10px;
	text-align: left;
	background: $gray6;
}

.checkout-tbl td {
	padding: 5px 10px;
}

.checkout-tbl .sm-col--price {
	white-space: nowrap;
}

.checkout .button--checkout {
	float: right;
	margin: 0 10px 5px 0;
}

.tbl-line td {
	border-top: 1px solid $gray6;
}

.checkout-tbl tbody tr:nth-child(even) td {
	background: $gray8;
}

.cart-total td {
	padding-top: 10px;
	font-size: 1.4em;
	font-weight: bold;
	border-top: 1px solid $gray6;
}

.cart-product {
	position: relative;
	padding-left: 24px;
}

.button--cart-remove {
	position: absolute;
	color: #888;
	font-weight: bold;
	text-transform: lowercase;
}

@media only screen and (max-width: 480px) {
	.checkout-tbl {
		border-top: 1px solid $gray6;
	}

	.checkout-tbl thead {
		display: none;
	}

	.checkout-tbl td {
		display: block;
		width: 100%;
	}

	.checkout-sku::before {
		content: attr(data-hdr);
		display: inline;
		font-weight: bold;
	}

	.tbl-line {
		display: block;
		border-top: 1px solid $gray6;
	}

	.tbl-line td {
		border: none;
	}

	.checkout-tbl tr {
		display: block;
		clear: both;
		overflow: hidden;
	}

	.checkout-tbl tfoot tr {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	.checkout-tbl tfoot td {
		float: left;
		width: auto;
		text-align: left;
	}

	.checkout-tbl tfoot td[colspan="3"] {
		grid-column: 1 / span 2;
	}

	.checkout-tbl tbody tr {
		padding: 10px 0;
	}

	.checkout-tbl tbody tr:nth-child(even) {
		background: $gray8;
	}

	.checkout-tbl .checkout-disclaimer {
		grid-column: 1 / span 2;
	}

	.checkout .button--checkout {
		float: none;
		margin-left: 10px;
	}

	.checkout-tbl .sm-col {
		float: left;
		width: 33.33333333%;
		text-align: left !important;
	}
}


// --- #order-history --- //

@import 'lib/components/order-history';


// --- #account --- //
@import 'lib/components/account/account-layout';
@import 'lib/components/account/account-nav';
@import 'lib/components/account/account-info';
@import 'lib/components/account/dashboard';


// --- #shopping-cart --- //

.cart-error {
	display: block;
	padding: 5px 10px;
	font-weight: bold;
}

.cart-tbl {
	width: 100%;
	margin: 15px 0;
	border-collapse: collapse;
	border-spacing: 0;
}

.cart-tbl .cart-tbl__check {
	max-width: 70px;
	padding: 10px 0;
}

.cart-tbl caption {
	padding: 0 10px 5px;
	text-align: left;
	overflow: hidden;
}

.cart-tbl .button--checkout {
	float: right;
	margin: 0 0 0 5px;
}

.cart-tbl .button--continueshopping {
	float: left;
}

.cart-tbl .button--updatecart {
	float: right;
	margin-right: 6px;
}

.cart-tbl thead {
	clear: both;
}

.cart-tbl th {
	padding: 10px;
	text-align: left;
	background-color: $gray7;
}

.cart-tbl th .button--line {
	margin-right: 10px;
}

/* .cart-tbl .alternate td { */
.cart-tbl tr:nth-child(even) td {
	background-color: $gray8;
}

.cart-tbl tr.tr--footer td {
	background-color: transparent;
}

.cart-tbl td {
	padding: 10px;
	text-align: left;
}

.cart-tbl .title {
	padding: 0;
	overflow: auto;
}

.cart-tbl .nopadding {
	padding: 0;
	text-align: left;
}

.cart-tbl .center {
	text-align: center;
	padding: 0 20px;
}

.cart-available {
	margin: 5px 0 0;
}

.cart-update {
	display: block;
	margin: 5px 0 0;
}

.cart-desc {
	padding: 0 0 10px;
}

.cart-tbl .cart-save {
	padding: 0 10px;
	color: $red;
	font-style: italic;
	font-weight: normal;
	text-align: right;
}

.cart-tbl .cart-total {
	font-size: 1.4em;
	font-weight: bold;
}

.cart-sku {
	font-size: 0.8em;
}

.cart-tbl .name {
	max-width: 480px;
}

.header--cart {
	float: left;
}

// .current-ship {
// 	float: right;
// 	max-width: 100%;
// 	padding: 10px;
// }

// .current-ship .input {
// 	margin-bottom: 5px;
// }

// .current-ship label {
// 	font-weight: bold;
// }

.current-ship p {
	margin: 0;
	padding: 0;
	text-align: right;
}

.add-from-list {
	float: right;
	padding-top: 10px;
}

.add-from-section {
	float: left;
	padding-left: 4px;
}

.quick-order .add-from-list {
	padding-top: 17px;
}

// [TODO: Please rename me.]
.pricesmallstrike {
	color: $gray4;
	font-size: 0.8em;
	font-weight: normal;
	text-transform: uppercase;
}

@media only screen and (max-width: 680px) {
	.add-from-list {
		float: none;
		clear: both;
		padding: 10px;
	}

	.add-from-section {
		float: none;
		width: 100%;
		padding: 0 0 5px;
	}
}

@media only screen and (max-width: 480px) {
	.cart-tbl th {
		display: none;
	}

	.cart-tbl tbody tr {
		display: block;
		overflow: hidden;
	}

	.cart-tbl td {
		display: block;
	}

	.cart-tbl .cart-sm {
		float: left;
		width: 33.3333333%;
		padding: 10px;
	}

	.cart-tbl .alternate {
		background-color: $gray8;
	}

	.yourprice {
		display: block;
	}
}


@media only screen and (max-width: 420px) {
	.add-from-section select, .add-from-section .button {
		display: block;
		width: 100%;
		margin-bottom: 5px;
	}

	.add-from-section .button {
		text-align: center;
	}
}

// --- #shopping-cart-refactored --- //

@import 'lib/components/shopping-cart';


// --- #shopping-list --- //

.list-tbl {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
}

.list-tbl tr td {
	border-bottom: 1px solid $gray6;
}

.list-tbl tr:nth-child(even) td {
	background-color: $gray8;
	border-bottom: none;
}

.list-tbl tr:nth-child(odd) td {
	border-bottom: none;
}

.list-tbl td {
	padding: 5px;
}

.tbl-list__cart {
	padding: 5px;
}

.tbl-list__col {
	display: inline-block;
	margin: 0 0 0 5px;
	vertical-align: middle;
}

.list-sku {
	padding: 2px 0 0;
	margin: 0;
	font-size: 0.8em;
}

.tbl-list__actions {
	max-width: 110px;
}

.modify-list {
	margin: 0 0 10px;
}

.list-select {
	padding: 10px 10px 0;
}

.list-actions {
	padding: 10px 5px;
}

// .list-actions-left {
// 	float: left;
// }

.list-actions-right {
	float: right;
}

.list-add-error {
	margin: 0;
	padding: 0 0 0.5em 5px;
}

@media only screen and (max-width: 840px) {
	.tbl-list__actions .button {
		display: block;
		text-align: center;
		margin: 0 0 5px;
	}
}


// --- #home-page --- //

.home__text {
	margin: 15px;
	text-align: center;
}

.home__text img {
	display: block;
	height: auto;
	max-width: 100%;
	margin: 0 auto;
}

.main-banner-wrap .bx-controls-direction {
	display: none;
}

.main-banner-wrap .bx-wrapper .bx-prev {
	left: 10px;
}

.main-banner-wrap .bx-wrapper .bx-next {
	right: 10px;
}

.banner-feature img {
	display: block;
	width: 100%;
	height: auto;
}

.banner-feature a {
	display: inline-block;
}

.banner-feature .banner-feature--first {
	display: block;
}

.bar {
	padding: 8px;
	color: $white;
	font-weight: bold;
	background-color: $gray1;
	overflow: auto;
}

.bar a {
	color: $white;
	font-size: 0.8em;
}

.slidewrap img {
	display: block;
	max-width: 100%;
	height: auto;
	margin: 0 auto;
	visibility: visible;
	opacity: 1;
	transition: opacity 0.5s;
}

.slidewrap a {
	color: $gray1;
}

.slider-outer {
	overflow: hidden;
}

.slider-caption {
	margin: 8px 0 0;
	padding: 0 5px;
	font-size: 0.9em;
	font-weight: bold;
	text-align: center;
}

.slidewrap .slide-hidden {
	opacity: 0;
}

// only show the pager if there are greater than 1 pages
.post .bx-pager-item:first-of-type:last-of-type {
	display: none;
}

@import './lib/components/scroller-featured';


// --- #home-articles --- //

.header--home,
.home-articles__header {
	position: relative;
}

.home-articles {
	overflow: hidden;
}

.home-articles__list {
	margin: 0 -15px 20px;
}

.home-articles__item {
	float: left;
	width: 33.3333333333%;
	margin-top: 30px;
	padding: 0 15px;
}

.home-articles__item:nth-child(3n+1) {
	clear: both;
}

.home-article__img {
	margin-bottom: 10px;
}

.home-article__img img {
	display: block;
	width: 100%;
	height: auto;
	margin: 0 auto;
}

.home-article__title {
	margin: 0;
	padding: 0;
	font-size: 1.25em;
	text-transform: uppercase;
}

.home-article__link {
	display: block;
}

.home-article__link--read {
	text-transform: uppercase;

	&::after {
		content: ' >';
	}
}

.home-article__link:hover {
	text-decoration: none;
}

.home-article__link:hover .home-article__title {
	text-decoration: underline;
}

.home-article__more {
	display: none;
	visibility: hidden;
}

.home-article__text p {
	margin: 3px 0 0;
	padding: 0;
	color: #6d6e71;
	font-size: 11px;
}

.home-article__text {
	position: relative;
}

.home-article__teaser {
	color: inherit;
}

.home-articles__more {
	clear: both;
	display: block;
	text-align: right;
	text-transform: uppercase;
	background: #6d6e71;
}

.home-articles__more:hover span {
	text-decoration: underline;
}

.home-articles__more span {
	display: inline-block;
	padding: 2px 5px 2px 10px;
	background: $white;
}

.home-articles__more span::after {
	content: ' >';
}

@media only screen and (max-width: 1045px) {
	.home-articles__list {
		margin-right: 0;
		margin-left: 0;
	}
}

@media only screen and (max-width: 680px) {
	.home-articles__item {
		width: 50%;
	}

	.home-articles__item:nth-child(3n+1) {
		clear: none;
	}

	.home-articles__item:nth-child(2n+1) {
		clear: both;
	}
}

@media only screen and (max-width: 420px) {
	.home-articles__list {
		margin-left: 0;
		margin-right: 0;
	}

	.home-articles__item {
		float: none;
		width: 100%;
	}

	.home-article__img img {
		width: 100%;
	}

	.home-article__text {
		margin: 0;
	}
}

// --- #standard banners --- //

@import 'lib/components/standard-banner';


// --- #home-facebook --- //

.home-articles__list--fb {
	float: left;
	width: 66.666666%;
	padding-right: 30px;
}

.home-articles__list--fb .home-articles__item {
	width: 50%;
}

.home-articles__list--fb .home-articles__item:nth-child(3n+1) {
	clear: none;
}

.home-articles__list--fb .home-articles__item:nth-child(2n+1) {
	clear: both;
}

.fb-feed {
	float: right;
	width: 33.333333333%;
}

@media only screen and (max-width: 600px) {
	.fb-feed {
		width: 50%;
	}

	.home-articles__list--fb {
		width: 50%;
	}

	.home-articles__list--fb .home-articles__item {
		width: 100%;
	}
}

@media only screen and (max-width: 500px) {
	.fb-feed {
		float: none;
		width: 100%;
		margin-bottom: 30px;
	}

	.home-articles__list--fb {
		float: none;
		width: 100%;
		margin-right: 0;
		margin-left: 0;
		padding-right: 0;
	}
}



// --- #fade-rotator --- //

.fader {
	position: relative;
	z-index: 10;
}

.fader__spacer {
	width: 100%;
	padding-bottom: 100%;
}

.fader__list > li {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.fader__item {
	z-index: 100;
	opacity: 0;
	transition: opacity 2s;
}

.fader__item--active {
	z-index: 200;
	opacity: 1;
	transition: opacity 1s;
}

.fader__title {
	margin: 0;
	padding: 5px;
	color: $white;
	font-size: 0.9em;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
	background-color: $black;
}

.fader img {
	display: block;
	width: 100%;
	height: auto;
}


// --- #header-message --- //

.header-message {
	position: absolute;
	top: 0;
	right: 0;
	padding: 5px;
	color: $gray3;
}


// --- #article-posts --- //
.blog__title {
	font-size: 2.75em;
	margin-bottom: 0.75em;
}

.post {
	padding: 20px 0 0;
}

.post__header {
	margin: 0 0 5px;
	text-align: center;
}

.post__subhead {
	margin: 2em 0 0.25em;
	padding: 0 0 0.5em;
	font-size: 1em;
	font-weight: normal;
	text-transform: uppercase;
	border-bottom: 1px solid #d1d1d1;
}

.post__subhead:first-child {
	margin-top: 0;
}

.post__link {
	margin: 0;
	padding: 0;
}

.post__title {
	margin: 0;
	padding: 0;
}

.post__teaser, .post__body {
	line-height: 1.4;
}

.post__teaser {
	font-style: italic;
}

.post__body ol, .post__body ul {
	overflow: hidden;
}

.post__text {
	position: relative;
	margin: 0 0 30px;
	padding: 0 0 20px;
	border-bottom: 1px solid #d1d1d1;
}

.post__text:last-of-type {
	border-bottom: none;
}

.post__primary {
	position: relative;
	float: left;
	width: 75%;
	padding: 0 25px;
}

.post__secondary {
	position: relative;
	float: right;
	width: 25%;
}

.post__tertiary {
	float: right;
	clear: right;
	width: 25%;
	padding-top: 10px;
}

.post__more {
	position: absolute;
	right: 5px;
	bottom: 5px;
}

.post__more::after {
	content: ' \00bb';
}

.post__img, .post__teaser, .post__more, .post__body {
	display: block;
	margin: 0 0 10px;
}

.post__teaser--logged-out {
	position: relative;
	width: 85%;
	margin: 20px auto 0;
	padding: 10px 0 30px;
	text-align: center;
}

.post__teaser--logged-out::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	width: 60%;
	margin: auto;
	height: 1px;
	border-top: 1px solid #ddd;
}

.post__img img, .post__body img {
	display: block;
	max-width: 100%;
	height: auto;
	margin: 0 auto;
}

.post__text--list .post__header {
	margin-bottom: 10px;
	text-align: left;
}

.post__text--list .post__img {
	float: left;
	width: 33.3333333%;
	padding: 0 15px 15px 0;
}

@media only screen and (max-width: 640px) {
	.post__primary {
		float: none;
		width: 100%;
		left: auto;
		right: auto;
		padding: 0 10px;
	}

	.post__secondary, .post__tertiary {
		clear: none;
		float: none;
		width: 100%;
		padding: 40px 60px 0;
	}

	.post__tertiary {
		padding-bottom: 20px;
	}

	.post__text--list .post__img {
		float: none;
		width: 100%;
	}

	.post__more {
		position: static;
		text-align: right;
	}
}

@media only screen and (max-width: 480px) {
	.post__secondary, .post__tertiary {
		padding: 40px 20px 0;
	}

	.post__tertiary {
		padding-bottom: 20px;
	}
}

// --- #tag-cloud --- //

.cloud {
	margin: 15px 0;
	padding: 10px 5px;
	line-height: 1.8em;
	text-align: center;
	border-style: solid;
	border-color: #d1d1d1;
	border-width: 1px 0;
}

.cloud__list > li {
	display: inline;
}

.cloud__tag--xs { font-size: 0.9em; }

.cloud__tag--s  { font-size: 1.1em; }

.cloud__tag--m  { font-size: 1.3em; }

.cloud__tag--l  { font-size: 1.5em; }

.cloud__tag--xl { font-size: 1.7em; }


// --- #gallery --- //

.gallery {
	margin: 0 -5px 15px;
}

.gallery__item {
	float: left;
	width: 33.33333333%;
	padding: 0 5px 10px;
}

.gallery__item > img {
	width: 100%;
	height: auto;
	cursor: pointer;
}

.gallery__categories {
	margin: 15px 0 5px;
}

.gallery__categories a {
	font-weight: bold;
}

.gallery__categories > li {
	margin-right: 4px;
}

.gallery__overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.6);
}

.overlay__content {
	position: absolute;
	top: 50%;
	left: 50%;
	min-width: 150px;
	min-height: 150px;
	background: $black;
	transform: translateY(-50%) translateX(-50%);
}

.gallery__image img {
	display: block;
	max-height: 70vh;
	max-width: 90vw;
	margin: 0 auto;
}

.overlay__next, .overlay__prev, .overlay__close {
	display: block;
	width: 2em;
	height: 2em;
	color: #efefef;
	font-size: 1.5em;
	line-height: 2em;
	text-align: center;
	background: rgba(0, 0, 0, 0.5);
}

.overlay__next:hover, .overlay__prev:hover, .overlay__close:hover {
	text-decoration: none;
}

.overlay__next {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	margin: auto 0;
}

.overlay__prev {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	margin: auto 0;
}

.overlay__close {
	position: absolute;
	top: 0;
	right: 0;
}

.gallery__share {
	position: absolute;
	bottom: -15px;
	margin: 0 0 10px 10px;
	opacity: 0;
	transition: opacity 0.3s, bottom 0.3s;
}

.overlay__content:hover .gallery__share {
	bottom: 0;
	opacity: 1;
	transition: opacity 0.3s, bottom 0.3s;
}

@media only screen and (max-width: 540px) {
	.gallery__item {
		width: 50%;
		padding: 0 2px 2px;
	}

	.gallery__image img {
		max-width: 90vw;
		max-height: 90vh;
	}
}


// --- #saved-cards --- //

.saved-cards {
	margin: 15px 0 0;
	padding: 10px;
	background: #fbfbfb;
	border: 1px solid #ddd;
	border-radius: 3px;
}

.saved-cards__header {
	margin: 0;
	padding: 0 0 0.75em;
	font-size: 1.15em;
}

.saved-cards__list {
	max-height: 235px;
	margin: 0;
	padding: 0;
	list-style: none;
	overflow-y: auto;
}

.saved-cards__note {
	margin: 0;
	padding: 0 0 0.5em;
	color: #6b6b6b;
	font-size: 0.8em;
	font-style: italic;
}

.saved-cards__list > li {
	margin: 0 0 10px;
}

.saved-cards input[type="radio"] {
	margin: 0 3px 0 0;
	vertical-align: middle;
}


// --- #popover --- //

.popover__background {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 9000;
	background: rgba(0, 0, 0, 0.5);
}

.popover__content {
	position: fixed;
	top: 50%;
	left: 50%;
	z-index: 9001;
	width: 80%;
	max-width: 500px;
	max-height: 95vh;
	overflow-y: auto;
	text-align: center;
	background: transparent;
	transform: translate(-50%, -50%);
}

.popover__close {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	padding: 10px;
	width: 25px;
	height: 25px;
	background-image: url("/images/cache/close_icon.jpg");
	border: none;
	cursor: pointer;
}

.popover__link {
	display: block;
	color: inherit;
}

.popover__link--main {
	margin: 10px 0 0;
	font-size: 16px;
}

.popover__link--main:first-child {
	margin-top: 0;
}

.popover__text {
	padding: 10px 0;
	background-color: #fff;
	margin-top: -1px;

	&.is--png {
		background: transparent;
	}
}

.popover img {
	display: block;
	width: 100%;
	height: auto;
}

.popover h2 {
	margin: 0 0 10px;
	padding: 0;
	color: #959595;
	font-size: 24px;
	font-weight: normal;
	line-height: 1;
	text-align: center;
}

.popover p {
	padding: 0 25px;
	font-size: 14px;
}


// --- #email-signup

@import 'lib/components/email-signup';


// --- #sample-program --- //

@import 'lib/components/sample-program';


// --- #new-cart --- //

.cart-table {
	width: 100%;
	margin: 15px 0 0;
	border-collapse: collapse;
	border-spacing: 0;
}

.cart-table__actions--right {
	float: right;
	margin: 5px 10px;
}

.cart-table__actions--left {
	float: left;
	margin: 5px 10px;
}

.cart-table__total {
	font-size: 1.4em;
	font-weight: bold;
}

.cart-table__sku {
	font-size: 0.8em;
}

.cart-table__desc {
	padding: 0 0 10px;
}

.cart-table__update {
	display: block;
	margin: 5px 0 0;
}

.cart-table__name {
	max-width: 480px;
}

.cart-table td, .cart-table th {
	padding: 10px;
}

.cart-table__header th {
	text-align: left;
	background-color: $gray7;
}

// .cart-table__caption .button {
// 	display: block;
// 	width: 100%;
// 	margin: 0 0 5px;
// }

.cart-table .cart-table__savings {
	padding-top: 0;
	color: $red;
	font-style: italic;
}

.cart-table__body tr:nth-child(even) td {
	background-color: $gray8;
}

.cart-table__footer tr:first-child td {
	padding-top: 20px;
	border-top: 1px solid #ccc;
}


// --- #home-categories --- //

.home-blog-categories {
	max-width: 1000px;
	margin: 0 auto;
	overflow: hidden;
}

.home-blog-category {
	margin: 15px 0;
}

.home-blog-category__header {
	position: relative;
	min-height: 80px;
	padding: 15px 10px 5px;
	background: #676767;
	color: $white;
	overflow: hidden;
}

.home-blog-category__image {
	position: absolute;
	right: 10px;
	bottom: 5px;
}

.home-blog-category__content {
	padding: 0 10px;
	line-height: 1.5;
}

.home-blog-category__link {
	font-size: 0.9em;
	font-weight: bold;
	text-transform: uppercase;
	white-space: nowrap;
}

.home-blog-category__link::after {
	content: ' >';
}

.home-blog-category__header > h2 {
	position: absolute;
	bottom: 5px;
	left: 10px;
	margin: 0;
	padding: 0;
	font-weight: normal;
	text-transform: uppercase;
}

@media only screen and (min-width: 480px) {
	.home-blog-categories__inner {
		margin: 0 -5px;
	}

	.home-blog-category {
		float: left;
		width: 50%;
		padding: 0 5px;
	}

	.home-blog-category:nth-child(2n+1) {
		clear: both;
	}
}

@media only screen and (min-width: 780px) {
	.home-blog-category {
		width: 25%;
	}

	.home-blog-category__header {
		min-height: 125px;
	}

	.home-blog-category:nth-child(2n+1) {
		clear: none;
	}

	.home-blog-category:nth-child(4n+1) {
		clear: both;
	}
}

@media only screen and (min-width: 1000px) {
	.home-blog-category__content {
		padding: 0;
	}
}


// --- #pencil-banner --- //

.pencil-banner {
	display: block;
	max-width: 1000px;
	margin: 0 auto;
}

.pencil-banner__image {
	display: block;
	max-width: 100%;
	height: auto;
	margin: 0 auto;
}

.pencil a {
	color: inherit;
}

.pencil > div:not(.pencil-banner) {	
	text-align: center;
	padding: 12px 10px;
}

.pencil--text a {
	display: inline-block;
}

// --- #explore deals --- //

@import 'lib/components/explore-deals';

// --- #dev-message --- //

.dev-message {
	max-width: 1000px;
	margin: 0 auto;
	padding: 5px 5px 0;
	color: $gray1;
	background: $white;
}

.dev-message strong {
	text-transform: uppercase;
	color: $red;
}


// --- #multi-select --- //

@import 'lib/components/multi-select';


// --- #login-modal --- //

.login-modal {
	display: none;
}

.login-modal__content {
	position: fixed;
	top: 50%;
	right: 0;
	left: 0;
	z-index: 9000;
	width: 90%;
	max-width: 800px;
	max-height: 80vh;
	margin: auto;
	padding: 30px 5px;
	transform: translateY(-50%);
	background: #fff;
	border: 1px solid #ddd;
	box-shadow: 0 0 15px rgba(0, 0, 0, 1);
	overflow-y: auto;
}

.login-modal li {
	margin-bottom: 15px;
	overflow: hidden;
}

.login-modal li:last-child {
	margin-bottom: 0;
}

.register-modal-error > p {
	margin: 0;
	padding: 15px 0 0;
}

.form--half {
	float: left;
	width: 49%;
}

.form--half:first-child {
	margin-right: 1%;
}

.form--half:last-child {
	margin-left: 1%;
}

.fields--deactivated {
	opacity: 0.4;
	transition: opacity 0.3s;
}

.login-modal__img {
	display: block;
	width: 100%;
	height: auto;
	margin: 0 auto;
}

@media only screen and (max-width: 680px) {
	.login-modal {
		padding-top: 45px;
	}

	.login-modal__img {
		margin-bottom: 20px;
	}
}


// --- #toggler --- //

.toggler__label {
	display: block;
	float: left;
	cursor: pointer;
}

.toggler__radio {
	position: absolute;
	left: -9999px;
}

.toggler__text {
	display: block;
	width: 100px;
	padding: 6px 14px;
	font-size: 0.85em;
	text-align: center;
	border: 1px solid #ddd;
	border-right: none;
}

.toggler__radio:checked + .toggler__text {
	color: #fff;
	background: #8d8d8a;
	border-color: #8d8d8a;
}

.toggler__label:first-child .toggler__text {
	border-radius: 3px 0 0 3px;
}

.toggler__label:last-child .toggler__text {
	border-right: 1px solid #ddd;
	border-radius: 0 3px 3px 0;
}


// --- #or --- //

.or {
	position: relative;
	display: inline-block;
	margin: 0 10px;
	padding: 0 15px;
}

.or::before {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10;
	width: 100%;
	height: 1px;
	margin: auto;
	background: #ddd;
}

.or__inner {
	display: inline-block;
	position: relative;
	z-index: 20;
	padding: 0 3px;
	background: #fff;
}


// --- #autocomplete --- //

@import 'lib/vendor/auto-complete';

// --- #list-edit --- //

.list-actions-left {
	float: none;
}

.list-management {
	margin-bottom: 15px;
}

.list-management__text {
	width: 100%;
	max-width: none;
	margin-top: 2px;
}

.list-management__actions {
	display: block;
}

.list-modal {
	position: relative;
	z-index: 10000;
}

.list-modal__actions {
	margin-top: 15px;
}

.list-modal .popover__content {
	max-width: 350px;
	padding: 10px 20px;
	text-align: left;
	background: $white;
}

@media only screen and (min-width: 480px) {
	.list-actions-left {
		float: left;
	}

	.list-management {
		margin-bottom: 0;
	}

	.list-management__text {
		max-width: 175px;
	}

	.list-management__actions {
		display: inline;
	}
}


// --- #instagram-widget --- //

@import 'lib/components/instagram-widget';

// --- #gdpr-notice --- //
@import 'lib/components/gdpr-notice';

// --- dialogs ---//
@import 'lib/components/dialog';


// ---=== Overrides ===--- //


// --- #sharing-overrides --- //

.fb_iframe_widget span {
	vertical-align: baseline !important;
}


// --- #layout-overrides --- //

.errorborder {
	border: 1px solid $red !important;
	background-color: $red-light !important;
}

.hidden {
	display: none !important;
	visibility: hidden !important;
}


// --- #text-overrides --- //

.bold {
	font-weight: bold;
}

.padd2 {
	padding: 2px;
}

.center {
	text-align: center;
}

.txt-left {
	text-align: left !important;
}

.txt-right {
	text-align: right !important;
}

.txt-center {
	text-align: center !important;
}

.upper {
	text-transform: uppercase;
}

.lower {
	text-transform: lowercase;
}

.floatleft {
	float: left;
}

.floatright {
	float: right;
}


// --- #clearfix --- //

.cf::after {
	content: '';
	display: table;
	clear: both;
}

.clear {
	clear: both;
}


// --- #temporary-overrides --- //

.radrating {
	display: none !important;
	visibility: hidden !important;
}


@import 'lib/components/qty-break';

/*--- Lazy load images with animation ---*/
.lazy {
  opacity: 0;
  min-height: 1px;
}

@keyframes visible {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.is--visible {
  animation: visible 0.8s;
}

/*--- End Lazy load images with animation ---*/

@import 'lib/components/landing-password';

@import 'lib/components/checkout';

@import 'lib/components/in-store';


.--pending, .is--pending {
	position: relative;
	pointer-events: none;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 100;
		background-color: rgba(0, 0, 0, 0.3);
	}

	&::after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 200;
		width: 1em;
		height: 1em;
		margin: auto;
		font-size: 12px;
		border-radius: 100%;
		animation: circle 1s infinite ease;
	}
}

@import 'lib/components/404';
@import 'lib/components/video-placeholder';
@import 'lib/components/marketplace-checkout';
