.calendar {
	clear: both;
	padding: 30px 0;
}

.calendar__row {
	border-top: none;
}

.calendar__row:last-of-type {
	border-top: none;
}

.calendar__row--header {
	display: none;
	border: none;
	border-bottom: 1px solid #ddd;
}

.calendar__day {
	position: relative;
	min-height: 80px;
	padding: 25px 10px 5px;
	border-top: 1px solid #ddd;
	border-right: 1px solid #ddd;
	border-left: 1px solid #ddd;
}

.calendar__row:last-child {
	border-bottom: 1px solid #ddd;
}

.calendar__day:empty, .not-this-month {
	display: none;
	background: #f3f3f3;
}

.calendar__day-of-week {
	min-height: 0;
	font-weight: bold;
	text-align: center;
	border: none;
}

.calendar__date {
	position: absolute;
	top: 5px;
	right: 5px;
	color: #999;
}

.calendar__event {
	display: block;
	margin: 4px 0;
	padding: 4px;
	color: rgba(0, 0, 0, 0.9);
	font-size: 11px;
	border-radius: 3px;
	background: #ddd;
}

.not-this-month .calendar__date {
	color: #cdcdcd;
}

.calendar__header {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}

.calendar__title {
	flex: 1 1 100px;
	padding: 5px;
	font-size: 18px;
	font-weight: bold;
	text-align: center;
}

.calendar__nav {
	flex: 3 1 auto;
}

.calendar__nav--prev {
	text-align: right;
}

.calendar__button {
	display: inline-block;
	width: 28px;
	height: 28px;
	border: none;
	font-size: 0px;
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: 0 0;
	background-size: 28px 28px;
	cursor: pointer;
}

.calendar__button--prev {
	background-image: url('/content/themes/trubeauty/images/arrow-left-thick.png');
}

.calendar__button--next {
	background-image: url('/content/themes/trubeauty/images/arrow-right-thick.png');
}

.calendar__button--disabled {
	opacity: 0.3;
	cursor: default;
}

@media only screen and (min-width: 800px) {
	.calendar__row {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		border: 1px solid #ddd;
		border-top: none;
	}

	.calendar__row--header {
		display: flex;
	}

	.calendar__day {
		flex: 0 0 calc(100% / 7);
		border-bottom: none;
		border-right: none;
		border-top: none;
		border-left: 1px solid #ddd;
	}

	.calendar__row--header {
		border-right: none;
		border-left: none;
	}

	.calendar__day-of-week {
		border-right: none;
		border-left: none;
	}

	.calendar__day:empty, .not-this-month {
		display: block;
	}

	.calendar__day:first-of-type {
		border-left: none;
	}

	.calendar__row:last-child .calendar__day {
		border-bottom: none;
	}
}
