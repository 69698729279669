.dashboard__email-required {
    .popover__content {
        max-width: 350px;
        padding: 15px;
        text-align: left;

        p {
            margin: 0 0 15px 0;
            padding: 0;
        }
    }

    .lbl--error {
        display: block;
        margin-bottom: 15px;
    }
}

.single-input {
  display: flex;
  flex-direction: row;

  .input--full {
    flex: 1;
  }

  .button {
    flex: 0 auto;
    border-radius: 0;
  }
}

.dashboard .recent-orders,
.dashboard .top-items,
.dashboard .on-sale-now,
.dashboard .loyalty-programs,
.dashboard .loyalty-credit,
.dashboard .sample-programs {
    margin-top: 50px;
}

.dashboard-banners {
    .standard-banner {
        margin-top: 0px;
        margin-bottom: 10px;
    }

    .standard-banner__button {
        display: none;
    }

    .standard-banner__title {
        display: none;
    }
}

.first-bucket {
    margin-bottom: 10px;
}

.dashboard__title {
  margin: 0 0 15px;
  padding: 0;
}

.dashboard__tagline {
  width: 100%;
  margin: 0;
  padding: 0;
}

.dashboard-table {
    width: 100%;
    font-size: 12px;
    text-align: left;
    border: 1px solid #ddd;
    border-collapse: collapse;
    border-radius: 3px;

    thead {
        th {
            padding: 5px;
            line-height: 1;
            background-color: #ededed;
            white-space: nowrap;
        }
    }

    tbody,
    tfoot {
        td {
            padding: 5px;
            border-top: 1px solid #ddd;
        }
    }

    .button-search {
        margin: 0 auto;
    }

    .button-save {
        margin: 0 auto;

        img {
            display: block;
            max-width: 100%;
            height: auto;
            margin: 0 auto;
        }
    }
}

.dashboard-table__track a {
  color: inherit;
  text-decoration: underline;
}

.dashboard-table--currency {
  text-align: right;
}

.dashboard-table--center {
  text-align: center;
}

.dashboard-list,
.dashboard-products,
.dashboard-samples {
  border-bottom: 1px solid #ddd;
  line-height: 1.3;
}

.dashboard-list__item,
.dashboard-sample {
    position: relative;
    padding: 10px;
    border-top: 1px solid #ddd;
}

.dashboard-products {
    .dashboard-list__item {
        display: grid;
        grid-template-columns: 1fr 2fr auto;
        grid-gap: 10px;
        align-items: center;        
    }
}

.dashboard-sample {
    display: grid;
    grid-template-columns: 1fr 2fr auto;
    grid-gap: 10px;
    align-items: center;
}

.dashboard__no-items {
    padding: 0 10px;
}

.dashboard-list__qty {
  margin-top: 5px;
}

.dashboard-list__add-qty {
  text-align: center;

  input {
    margin-bottom: 5px;
  }
}

.dashboard-list__link {
  color: inherit;
}

.dashboard-list__name {
  margin: 0;
  padding: 0 10px 0 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.3;
}

.dashboard-list__size {
  font-size: 0.8em;
  white-space: nowrap;
}

.dashboard-list__sku,
.dashboard-sample__date,
.dashboard-disclaimer {
  font-size: 11px;
}

.dashboard-list__price {
  font-size: 12px;
  font-weight: bold;
  text-align: right;

  .price__current {
    font-size: 1.5em;
  }

  .price__label,
  .price__label--salon,
  .price__label--sale,
  .price__label--savings {
    font-size: 0.75em;
    font-weight: normal;
  }
}

.dashboard-list__image,
.dashboard-sample__image {
  max-width: 100px;
}

.dashboard-list__photo {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.dashboard-sample__title {
  font-weight: bold;
  text-transform: uppercase;
}

.dashboard__disclaimer {
    font-size: 0.9em;
    margin-top: 5px;
}

.dashboard-list__purchase {
    display: grid;
    align-items: flex-start;
    grid-template-columns: 1fr auto auto;
    grid-gap: 5px;
    justify-items: end;
    margin-top: 0;
}
.dashboard-list .dashboard-list__purchase {
    align-items: center;
}

.dashboard__header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 0 20px;

    .dashboard__title {
        margin-bottom: 0;
    }
}

.dashboard__footer {
    padding-top: 10px;
    text-align: right;
    padding-right: 20px;

    .button {
        margin-bottom: 5px;
    }
}

.dashboard__add-all {
    align-self: flex-end;
}

.dashboard__error {
    display: block;
    padding-bottom: 5px;
}

.dashboard__next-link {
    display: inline-block;
    padding-right: 5px;
    margin-left: auto;

    &::after {
        content: " >";
    }
}

.dashboard .button--small {
    border-radius: 0px;
    border: none;
}

@media only screen and (min-width: 480px) {
  .dashboard-list__item {
    display: grid;
    grid-template-columns: 1fr auto;
  }

  .dashboard-products .dashboard-list__item {
    grid-template-columns: auto 1fr auto;
    grid-gap: 10px;
  }

  .dashboard-sample {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-gap: 10px;
  }
}

@media only screen and (min-width: 820px) {
    .dashboard {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 15px;
    }

    .dashboard-list__item,
    .dashboard-sample {
        align-items: center;
    }

    .dashboard-list__purchase {
        grid-template-columns: auto 1fr auto;
        justify-items: start;
        justify-self: end;
        column-gap: 10px;
        margin-top: 10px;

        .input--qty {
            justify-self: center;
        }
    }    

    .dashboard-products .dashboard-list__purchase {
        grid-template-columns: auto auto;
    }
}