.utility-bar {
	background-color: #000;
}

.utility-bar__inner {
	position: relative;
	max-width: 1000px;
	margin: 0 auto;
}

.utility-bar__inner { // this can be removed later or something
	.header-search {
		width: 150px;
	}
}

.locator-feature {
	padding: 10px 0;
}

.account-header {
	position: relative;
	padding: 10px 5px;
	color: #fff;
	background-color: #000
}

.account-header__header-i-guess {
	display: grid;
	grid-template-columns: 1fr auto auto;
	text-align: right;
}

.account-header__title {
	grid-row: 1;
	margin: 0;
	padding: 0;
	font-family: 'Acumin Pro', 'acumin-pro', sans-serif;
	font-size: 14px;
	font-weight: normal;
	text-transform: uppercase;
}

.account-header__account-name {
	display: block;
}

.account-header__icon {
	display: none;
	grid-row: 1 / span 2;
	height: 40px;
	width: 40px;
	margin-left: 15px;
	overflow: hidden;

	.icon-person svg {
		width: 40px; // for IE
		height: 40px;
	}

	.icon-person {
		.icon-circle {
			fill: none;
			stroke: #fff;
			stroke-width: 2;
		}

		.icon-body, .icon-head {
			fill: #fff;
			stroke: none;
		}
	}

	@media only screen and (min-width: 820px) {
		display: block;
	}
}

.account-header__icon-link {
	display: block;
	width: 100%;
	padding: 0;
	background-color: transparent;
	border: none;
	cursor: pointer;
}

.account-header__toggle {
	display: none;
	grid-row: 1 / span 2;
	grid-column: 3;
	align-self: center;
	margin: 0;
	padding: 5px 10px;
	line-height: 1;
	background-color: transparent;
	border: none;
	cursor: pointer;

	svg {
		fill: #fff;
	}

	@media only screen and (min-width: 820px) {
		display: block;
	}
}


// --- Cart Icon --- //
.header-cart__link {
	display: inline-block;
	position: relative;
	top: 3px;
}

.header-cart__icon {
	display: block;
	width: 50px;

	svg {
		width: 50px; // for IE
		height: 50px;
		fill: #fff;
		transition: fill 0.3s;

		.header-cart--updated & {
			fill: green;
			transition: fill 0.3s;
		}
	}
}

.header-cart__value {
	position: absolute;
	top: 20%;
	right: 7%;
	left: 29%;
	display: block;
	color: #000;
	font-size: 12px;
	font-weight: bold;
	text-align: center;
}


.login-links {
	padding: 10px 0;
}

@media only screen and (max-width: 620px) {
	.header-cart__value {
		top: 17%;
	}
}
	
@media only screen and (min-width: 820px) {
	.top-header {
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 1000;
	}	
}

// === Layout === /
.utility-bar__inner {
	display: grid;
	grid-template-columns: auto 1fr auto;
}

.header-search {
	align-self: center;
}

.locator-feature {
	grid-column: 1;
	align-self: end;
}

.account-header {
	grid-column: 2;
}

.header-cart {
	grid-column: 3;
	align-self: center;
}

.login-links {
	grid-column: 2 / span 2;
	align-self: center;
	justify-self: end;

	.is--logged-in & {
		align-self: end;
	}
}
