// move somewhere else
.txt--left {
	text-align: left;
}

.txt--center {
	text-align: center;
}

.txt--right {
	text-align: right;
}


.button--refresh {
	display: inline-block;
	width: 18px;
	height: 18px;
	background: url('/images/refresh.png') no-repeat 0 0;
	overflow: hidden;
	text-indent: 100%;
	white-space: nowrap;
	border: none;
	line-height: 1;
	font-size: 0;
	opacity: 0.6;
	transition: 0.3s opacity;
}

.button--refresh:hover {
	opacity: 0.9;
}
// stop moving somewhere else

.cart__outer {
	overflow: hidden;
}

.cart-outer {
	.overlay {
		position: fixed;
	}

	.spinner-panel {
		top: 0;
		bottom: 0;
	}
}

.cart__table-inner {
	padding-bottom: 15px;
}

cart__table-inner-split {
	padding-bottom: 15px;
}

.cart__table {
	display: block;
	width: 100%;
	margin-bottom: 15px;
	border-collapse: collapse;
	border-top: 1px solid #ededed;
	border-bottom: 1px solid #ededed;
}

.cart__th {
	display: none;
}

.cart__row {
	display: flex;
	align-items: flex-end;
	flex-direction: row;
	flex-wrap: wrap;
	overflow: hidden;
}

.cart__row--repbrand {
    background-color:#eee;
}

.cart__row--repbrand_error {
    background-color:lightpink;
}

.cart__cell {
	flex: 1 33.3333333%;
	float: left;
	width: 33.33333%;
	padding: 10px;
}

.cart__empty {
	padding: 10px;
}

.cart__cell--description {
	float: none;
	flex: 3 0 100%;
	width: 100%;
}

.cart__row:nth-child(odd), .sample-placeholder:nth-child(odd) {
	background-color: #eee;
}

.cart__caption {
	padding: 10px 10px 20px;
	overflow: hidden;
}

.cart__caption--bottom {
	padding-top: 0;
	padding-bottom: 0;
	margin-bottom: 15px;
}

.cart__caption-right, .cart__caption-left {
	padding: 5px 0;
}

.cart__caption-right .button, .cart__caption-left .button {
	display: block;
}

.cart__overview-countdown {
	padding-top: 5px;
	font-size: 16px;
	text-align: center;
}

.cart__requested-qty {
	font-size: 0.85em;
	white-space: nowrap;
}

@media only screen and (min-width: 420px) {
	.cart__caption {
		margin: 0 -10px;
	}

	.cart__caption-right, .cart__caption--bottom .cart__caption-full {
		float: right;
		width: 50%;
		padding: 0 5px;
	}

	.cart__caption-left {
		float: left;
		width: 50%;
		padding: 0 5px;
	}
}

@media only screen and (min-width: 540px) {
	.cart__right {
		overflow: hidden;
	}

	.cart__overview {
		float: left;
		width: 50%;
		margin-top: 10px;
		overflow: hidden;
	}

	.cart__actions {
		float: right;
		width: 50%;
	}

	.cart__table {
		display: table;
		width: 100%;
		border-collapse: collapse;
		border-top: none;
		border-bottom: none;
	}

	.cart__th {
		display: table-cell;
		padding: 5px 10px;
		background: #fff;
		border-bottom: 1px solid #ededed;
	}

	.cart__foot {
		display: table-cell;
		padding: 5px 10px;
		background: #fff;
		border-top: 1px solid #ededed;
	}

	.cart__row {
		display: table-row;
	}

	.cart__cell {
		float: none;
		display: table-cell;
		width: auto;
	}

	.cart__row:nth-child(odd), .sample-placeholder:nth-child(odd) {
		background-color: transparent;
	}

	.cart__row:nth-child(odd) .cart__cell, .sample-placeholder:nth-child(odd) td {
		background-color: #eee;
	}
}

@media only screen and (min-width: 640px) {
	.cart__caption-right, .cart__caption-left {
		width: auto;
		padding: 5px 10px;
	}

	.cart__caption-right .button, .cart__caption-left .button {
		display: inline-block;
	}
}

@media only screen and (min-width: 820px) {
	.cart__actions, .cart__overview {
		float: none;
		width: 100%;
	}

	.cart__right {
		float: right;
		width: 250px;
		padding-left: 10px;
	}

	.cart__left {
		overflow: hidden;
	}

	.cart__table-inner {
		max-height: 400px;
		overflow-y: auto;
	}

	.cart__table-wrap {
		position: relative;
	}

	.cart__table-wrap::after {
		content: '';
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		width: 100%;
		height: 15px;
		background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
		pointer-events: none;
	}

	.cart__caption--bottom .cart__caption-full {
		display: none;
	}
}

.cart__overview {
	padding: 10px;
	background: #ededed;
}

.cart__overview-item {
	padding: 5px 0;
	margin: 0;
	font-size: 1.25em;
	font-weight: bold;
	overflow: hidden;
}

.cart__overview-item--savings {
	font-size: 0.9em;
}

.cart__overview-label {
	float: left;
}

.cart__overview-value {
	float: right;
}

.cart__overview-item:first-child {
	padding-top: 0;
}

.cart__overview-checkout {
	margin-top: 15px;
}


.also-like, .recently-viewed {
	margin-top: 15px;
	padding: 10px;
}

.also-like__wrap {
	position: relative;
}

.also-like__wrap::after {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	width: 15px;
	height: 100%;
	background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

.also-like__inner {
	max-width: 100%;
	overflow-x: auto;
}

.also-like__header, .recently-viewed__header {
	font-size: 1.25em;
	text-align: center;
	text-transform: capitalize;
}

.also-like--product {
	margin-top: 45px;

	.also-like__header {
		font-size: 1.75em;
	}
}

.recently-viewed.recently-viewed--product {
	margin-top: 30px;

	.recently-viewed__header {
		font-size: 1.75em;
	}
}

.prod-list--vertical {
	margin: 0;
	padding: 0;
}

.cart-update {
	white-space: nowrap;
}

.related-add {
	text-align: center;
}

.related-add__button {
	display: inline-block;
}

.also-like, .recently-viewed {
	.prod-list {
		justify-content: center;
	}

	.prod-list__header {
		font-size: 14px;
	}

	.button {
		font-size: 12px;
		text-transform: none;
	}

	.prod-list__item {
		width: 50%;
	}

	.prod-list__item:nth-child(2n+1) {
		clear: both;
	}

	.input--qty {
		margin-bottom: 5px;
	}
}

@media only screen and (min-width: 480px) {
	.also-like, .recently-viewed {
		.prod-list__item {
			width: 33.33333333%;
		}

		.prod-list__item:nth-child(2n+1) {
			clear: none;
		}

		.prod-list__item:nth-child(3n+1) {
			clear: both;
		}
	}
}

@media only screen and (min-width: 680px) {
	.also-like, .recently-viewed {
		.prod-list__item {
			width: 25%;
		}

		.prod-list__item:nth-child(3n+1) {
			clear: none;
		}

		.prod-list__item:nth-child(4n+1) {
			clear: both;
		}
	}
}

@media only screen and (min-width: 900px) {
	.also-like, .recently-viewed{
		.prod-list__item {
			width: 20%;
		}

		.prod-list__item:nth-child(4n+1) {
			clear: none;
		}

		.prod-list__item:nth-child(5n+1) {
			clear: both;
		}
	}
}

@media only screen and (min-width: 680px) {
	.also-like--product, .recently-viewed--product {
		.prod-list__item {
			width: 25%;
		}

		.prod-list__item:nth-child(3n+1) {
			clear: none;
		}

		.prod-list__item:nth-child(4n+1) {
			clear: none;
		}
	}
}

@media only screen and (min-width: 900px) {
	.also-like--product, .recently-viewed--product {
		.prod-list__item:nth-child(4n+1) {
			clear: none;
		}

		.prod-list__item:nth-child(5n+1) {
			clear: none;
		}
	}
}


.payment-options {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		margin: 5px 0;

		label {
			display: inline-block;
		}
	}
}

.multi-select--radio {
	margin: 0 0 15px;
}

// --- highlight items that are out of stock or limited stock
.check-inventory {
	.cart__row:not([data-apiqty=""]) .cart__cell {
		color: #fff;
		background-color: #c12428;

		.list-link, .cart-update {
			color: #fff;
		}
	}
}
