// --- Main Nav

.header-banner__section--menu {
	display: none;
}

.site-nav {
	position: fixed;
	top: 0;
	bottom: 0;
	z-index: 9000;
	width: 260px;
	padding-top: 2em;
	margin-left: -260px;
	background: #efefef;
	border-right: 1px solid #ddd;
	border-top: 1px solid #ddd;
	overflow-x: hidden;
	overflow-y: auto;
	transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.site-nav.is--active {
	transform: translateX(260px);
}

.site-nav__list {
	border-top: 1px solid #ddd;
}

.site-nav__close {
	position: absolute;
	top: 0;
	right: 5px;
	padding: 0.25em 0.5em;;
	font-size: 1.25em;
	font-weight: bold;
	line-height: 1;
	background: none;
	border: none;
}

.site-nav__item {
	border-bottom: 1px solid #ddd;
}

.site-nav__top {
	display: block;
	padding: 15px;
	background: #fafafa;
	color: #555;
	font-size: 1.05em;
	font-weight: bold;
	text-transform: uppercase;

	&:hover {
		text-decoration: none;
	}
}

.site-nav__top--has-sub::after {
	content: '\00a0\25B8';
}

.site-nav__sub {
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 100%;
	width: 100%;
	min-height: 1px;
	margin: 2em 0 0 0;
	border-top: 1px solid #ddd;
	overflow-y: hidden;
	// overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	transition: left 0.5s;
}

.site-nav__item--sub-active .site-nav__sub {
	left: 0;
	overflow-y: auto;
}

@media only screen and (min-width: 820px) {
	.site-nav {
		position: static;
		width: auto;
		max-width: 1000px;
		margin: 0 auto;
		padding: 0;
		background-color: #d6d6d6;
		border-top: none;
		border-right: none;
		transition: none;
		overflow: visible;
	}

	.site-nav__list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		border-top: none;
	}

	.site-nav__item {
		flex: 0 0 auto;
		border-bottom: none;
	}

	.site-nav__sub {
		display: none;
	}

	.site-nav__top {
		padding: 10px 20px;
		background-color: transparent;

		&--has-sub::after {
			content: '';
		}
	}

	.site-nav__top--inactive {
		cursor: default;
		pointer-events: none;
	}

	.site-nav__close {
		display: none;
	}

	.site-nav__item:hover {
		.site-nav__top {
			background-color: $gray9;
		}

		.site-nav__sub {
			display: block;
		}
	}

	.site-nav.is--active {
		transform: none;
	}

	.site-nav__item--sub-active .site-nav__sub {
		left: auto;
	}

	.site-nav__inner {
		display: table;
		margin: 0 auto;
	}
}


// --- Sub Nav
.sub-nav {
	padding-bottom: 2em;
	background: #efefef;
	border-left: 1px solid #ddd;
}

.sub-nav__col, .sub-nav__col--2, .sub-nav__col--3, .sub-nav__col--4 {
	margin: 0;
	padding: 0;
	list-style: none;
}

.sub-nav__back {
	display: block;
	font-weight: bold;
	border-bottom: 1px solid #ddd;
}

.sub-nav__back::before {
	content: '\25C2\00a0';
}

.sub-nav li {
	border-bottom: 1px solid #ddd;
}

.sub-nav a {
	display: block;
	padding: 10px;
	color: inherit;
	background: #fefefe;
	text-decoration: none;
}

.sub-nav .productlinecategory {
	margin: 20px 10px 5px;
	font-weight: bold;
	border-bottom: none;
}

.sub-nav__highlight {
	display: none;
}

@media only screen and (min-width: 820px) {
	.sub-nav {
		position: absolute;
		top: auto;
		right: auto;
		bottom: auto;
		left: auto;
		z-index: 1000;
		width: auto;
		min-width: 250px;
		margin-top: -1px;
		padding: 10px;
		font-size: 12px;
		background: #f4f4f4;
		border: none;
		border-radius: 0 4px 4px 4px;
	}

	.sub-nav__inner {
		background: #fff;
		padding: 10px;
		border-radius: 3px;
	}

	.sub-nav__col, .sub-nav__col--2, .sub-nav__col--3, .sub-nav__col--4 {
		float: left;
		width: 150px;
	}

	.sub-nav__col--2 {
		width: 200px;
	}

	.sub-nav__col--3 {
		width: 375px;
	}

	.sub-nav__col--4 {
		width: 158px;
		margin-bottom: 15px;
	}

	.sub-nav__col--4:nth-of-type(5n+1) {
		clear: both;
	}

	.sub-nav--big {
		left: 0;
		right: 0;
		max-width: 1000px;
		margin: 0 auto;

		.sub-nav__col {
			display: flex;
			flex-flow: column wrap;
			max-height: 500px;
			width: auto;
			float: none;
		}

		li {
			float: none;
			width: auto;
			max-width: 20%;
		}
	}

	.sub-nav li {
		border: none;
	}

	.sub-nav a {
		padding: 2px;
		background: none;
	}

	.sub-nav a:hover {
		text-decoration: underline;
	}

	.sub-nav .productlinecategory {
		margin: 0 0 5px;
		text-decoration: underline;
	}

	.sub-nav .sub-nav__back {
		display: none;
	}

	.sub-nav__highlight {
		display: block;
		font-weight: bolder;
		font-size: 11px;
		padding-bottom: 10px;
	}
}

// --- Toggler
.nav-toggles {
	display: grid;
	grid-template-columns: auto auto;
	justify-content: space-between;
	background-color: $gray6;
}

.nav-toggle {
	display: block;
	clear: both;
	position: relative;
	z-index: 800;
	width: 100%;
	height: auto;
	padding: 10px 15px;
	color: $white;
	font-weight: bold;
	line-height: 1;
	text-align: left;
	text-decoration: none;
	background-color: $gray6;
	border: none;
	cursor: pointer;

	&:hover {
		text-decoration: none;
	}

	.icon-person {
		display: inline-block;
		fill: $white;
		width: 16px;
		vertical-align: text-top;
	}
}

.header-menu-toggle {
	display: none;
}

@media only screen and (min-width: 820px) {
	.nav-toggle, .header-menu-toggle {
		display: none;
	}
}


// --- Overlay

.menu-overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 8000;
	background: $black;
	opacity: 0;
	transition: opacity 0.3s ease-in;
}

.menu-overlay--active {
	opacity: 0.6;
}

@media only screen and (min-width: 820px) {
	.menu-overlay {
		display: none;
	}
}
