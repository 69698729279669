.price {
	font-size: 12px;
	line-height: 1.3;
	text-align: center;
}

.price--small {
	font-size: 11px;
	line-height: 1.2;
}

.price--large {
	font-size: 14px;
}

.price__label, .price__label--salon, .price__label--sale, .price__label--savings {
	display: block;
	margin: 0.2em 0;
	font-size: 0.9em;
	font-weight: bold;
	text-transform: uppercase;
}

.price__label--salon {
	color: $gray5;
	font-weight: normal;
}

.price__label--sale, .price__label--savings {
	color: $red;
}

.price__label--savings {
	font-style: italic;
	text-transform: none;
}

.price__sizes {
	font-weight: bold;
}

.price__current {
	font-size: 3em;
	font-weight: bold;
	line-height: 1;
	vertical-align: middle;
}

.price--small .price__current {
	font-size: 1.8em;
}

.price--compact .price__label, .price--compact .price__label--sale {
	display: inline;
}

.price--compact .price__current {
	vertical-align: baseline;
}

.for-price, .for-price--large {
	color: $gray1;
	font-size: 1.6em;
	text-decoration: underline;
}

.for-price, .for-price:hover {
	font-size: 1em;
	font-weight: bold;
}

.prod-pricing {
	position: relative;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	margin: 10px 0;
	padding: 5px 0;

	&::before, &::after {
		display: block;
	}

	.prod-qty label {
		margin-top: -1.25em;
	}
}

.prod-qty {
	padding-top: 15px;

	.input--qty {
		font-size: 2em;
		font-weight: bold;
	}
}

.js--add-list {
	margin-bottom: 0; // [TODO: Fix this]
}

@media only screen and (max-width: 680px) {
	.prod-add {
		margin: 15px 0 0;
	}
}

// ---
.prod-price {
	position: relative;
}

.prod-price__inner {
	align-items: center;
}

.prod-price__price {
	grid-row: 1 / span 2;
	flex: 1 1 auto;
	padding: 10px;
	text-align: center;
}

.prod-price__qty {
	grid-row: 1 / span 2;
	flex: 1 0 auto;
	padding: 10px;
	text-align: center;
	transform: translateY(-0.5em);

	.input--qty {
		// max-width: 5em;
		font-size: 2em;
		font-weight: bold;
	}
}

.prod-price__add {
	flex: 1 1 100%;
	margin-top: auto;
	padding: 0 10px;
	text-align: center;

	.button {
		margin-bottom: 0;
	}
}

.prod-price__list {
	grid-column: 3;
	margin-top: 5px;
	margin-bottom: auto;
	padding: 0 10px;
}

@media only screen and (min-width: 540px) {
	.prod-price__inner {
		display: grid;
		grid-template-columns: 1fr auto 1fr;
		grid-gap: 10px;
	}
}

.prod-price__notify {
	display: inline;
}

.increment {
	margin: 10px 0;
}

.quantity-template {
	display: none;
	visibility: hidden;
}

.product-tables {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.product-table {
	flex: 1 1 50%;
	margin: 20px 0;
}

.quantity-break {
	flex: 1 0 50%;
}

.product-table__header {
	margin: 0 0 0.5em;
	padding: 0;
	font-size: 1.15em;
}
