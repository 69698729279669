.myaccount-nav {
  margin: 20px 0;
}

.myaccount-nav__link {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding: 10px 25px 10px 30px; 
    border-radius: 25px;
    margin: 5px 10px;
    transition: 0.3s all;
    background-color: #bfbfbf;

    &--selected {
        background-color: #737373;
    }

    &:hover {
        text-decoration: none;
        background-color: #737373;
    }
}

.myaccount-nav__icon {
    text-align: center;

    img {
        width: 24px;
    }
}

.myaccount-nav__icon-qo img {
    width: 30px;
}

@media only screen and (max-width: 819px) {
    .myaccount-nav__icon {
        width: 100px;
        align-items: end;
        text-align: right;
    }
}

@media only screen and (max-width: 649px) {
    .myaccount-nav__icon {
        width: 50px;
        align-items: end;
        text-align: right;
    }
}

@media only screen and (max-width: 459px) {
    .myaccount-nav__icon {
        width: 110px;
        align-items: end;
        text-align: right;
    }
}

.myaccount-nav__text {
  flex: 1;
  align-self: center;
  margin-left: 10px;
}

.myaccount-nav__name {
  margin: 0;
  padding: 0;
  font-family: "Acumin Pro", "acumin-pro", sans-serif;
  font-weight: 600;
  color: #ffffff;
  font-size: 0.9em;
}

.myaccount-nav__description {
  margin: 5px 0 0;
  padding: 0;
}

@media only screen and (min-width: 460px) {
  .myaccount-nav {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (min-width: 820px) {
  .myaccount-nav {
    grid-template-columns: repeat(5, 1fr);
  }

  .myaccount-nav__link {
    margin: 0px 10px;
  }
}
